import { Grid, FormControl, InputLabel } from "@material-ui/core";
import moment from "moment";
import { getGroupCategoryById } from "../../utils/helper";
import {
  MuiPickersUtilsProvider,
  DatePicker as CustomReactDatepicker,
} from "@material-ui/pickers";
// Helper functions
const formatDate = (date) => moment(date).utc().format("YYYY-MM-DD");

const getDefaultExcludedTimes = (date_format) => {
  const times = [
    "00:00:00",
    "01:00:00",
    "02:00:00",
    "03:00:00",
    "04:00:00",
    "05:00:00",
    "06:00:00",
    "07:00:00",
    "17:00:00",
    "18:00:00",
    "19:00:00",
    "20:00:00",
    "21:00:00",
    "22:00:00",
    "23:00:00",
    "24:00:00",
  ];

  return times.map((time) => new Date(`${date_format} ${time}`));
};

const getExcludedTimesFromData = (data, date_format, category) => {
  let exclude_times = getDefaultExcludedTimes(date_format);

  if (data?.data?.data?.offdays?.length > 0) {
    // Exclude working hours (8:00 to 17:00) if the day is off
    for (let hour = 8; hour <= 17; hour++) {
      exclude_times.push(
        new Date(`${date_format} ${String(hour).padStart(2, "0")}:00:00`)
      );
    }
  }

  if (data?.data?.data?.length > 0) {
    // Check appointment slots for each category and time
    const group = getGroupCategoryById(category?.id);
    if (group) {
      data?.data?.data?.forEach((appointmentSlot) => {
        appointmentSlot.slots.forEach((slot) => {
          slot.available_slots.forEach((availableSlot) => {
            console.log(
              "Available Slots => " + availableSlot.available_slots_cap
            );
            if (group === "pms" && availableSlot.available_slots_pms === 0) {
              exclude_times.push(
                new Date(`${date_format} ${availableSlot.time}`)
              );
            }
            if (
              group === "car_buying" &&
              availableSlot.available_slots_car_buying === 0
            ) {
              exclude_times.push(
                new Date(`${date_format} ${availableSlot.time}`)
              );
            }
            if (
              group === "initial_diagnosis" &&
              availableSlot.available_slots_initial_diagnosis === 0
            ) {
              exclude_times.push(
                new Date(`${date_format} ${availableSlot.time}`)
              );
            }
          });
        });
      });
    }
  }

  return exclude_times;
};

// Component Code
const AppointmentDatePicker = ({
  theme,
  utils,
  values,
  errors,
  touched,
  setFieldValue,
  setPrompt,
  setExcludedTimes,
  fetchDataExcludedTimes,
  category,
}) => {
  const handleDateChange = async (date) => {
    if (!values.service_location) {
      setPrompt({
        open: true,
        title: "",
        message: "Service location is required to select an appointment date",
      });
      return;
    }

    const date_format = formatDate(date);
    setFieldValue("ap_date", date);
    setFieldValue("ap_time", null);

    // Fetch available slots
    const data = await fetchDataExcludedTimes(
      date_format,
      values.service_location
    );

    const exclude_times = getExcludedTimesFromData(data, date_format, category);

    setExcludedTimes(exclude_times);
  };

  return (
    <MuiPickersUtilsProvider theme={theme} utils={utils}>
      <Grid container>
        <FormControl fullWidth>
          <InputLabel
            style={{
              position: "absolute",
              top: "-40px",
              fontSize: "0.9rem",
            }}
          >
            Appointment Date
          </InputLabel>

          <CustomReactDatepicker
            minDate={new Date()}
            onChange={handleDateChange}
            error={errors.ap_date && touched.ap_date}
            helperText={
              errors.ap_date &&
              touched.ap_date &&
              "Appointment date is required."
            }
            value={values.ap_date}
            format="yyyy-MM-dd"
            size="small"
            inputVariant="outlined"
            name="ap_date"
            variant="inline"
            autoOk
            inputProps={{
              style: { textAlign: "center" },
            }}
          />
        </FormControl>
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

export default AppointmentDatePicker;
