import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const LoadingSpinner = ({ loading }) => {
  if (!loading) return null; // Return nothing if not loading

  return (
    <Box
      sx={{
        position: "fixed", // Position it above the content
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999, // Ensures the spinner is above other content
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default LoadingSpinner;
