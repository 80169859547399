import React, { Fragment } from "react";
import "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Divider,
  Dialog,
  Box,
  Paper,
  Grid,
  Tabs,
  Tab,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import CustomerVehicles from "./CustomerVehicles";
import CustomerAppointments from "./CustomerAppointments";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  customerInfoRoot: {
    padding: theme.spacing(3),
  },
  label: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  icon: {
    position: "absolute",
    top: "18px",
    marginLeft: "3px",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CustomerInfo = ({ data }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.customerInfoRoot}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <span className={classes.label}>Customer ID:</span> {data.id}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <span className={classes.label}>Name:</span>{" "}
            {`${data.firstname || ""} ${data.lastname || ""}`}{" "}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <span className={classes.label}>Phone:</span> {data.phone}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1">
            <span className={classes.label}>Email:</span> {data.email}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            <span className={classes.label}>Address:</span> {data.address}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const ViewCustomer = (props) => {
  const classes = useStyles();

  const { data, open, handleViewClose } = props;

  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleViewClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h5" className={classes.title}>
              View Customer
              <CalendarTodayIcon className={classes.icon} />
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleViewClose}
              aria-label="close"
            >
              <CloseIcon className={classes.button} />
            </IconButton>
          </Toolbar>
        </AppBar>
        <MuiDialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Box mt={3} mb={3}>
                <Typography variant="h6">Customer Profile</Typography>
                <Divider />
                <CustomerInfo data={data} />
              </Box>
            </Grid>
          </Grid>
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => {
              setActiveTab(newValue);
            }}
            aria-label="simple tabs example"
          >
            <Tab label="Customer Garage" {...a11yProps(0)} />
            <Tab label="Booking Appointments" {...a11yProps(1)} />
            <Tab label="Referrals" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={activeTab} index={0}>
            <CustomerVehicles customerId={data.id} />
          </TabPanel>
          <TabPanel value={activeTab} index={1}>
            <CustomerAppointments customerId={data.id} />
          </TabPanel>
          <TabPanel value={activeTab} index={2}>
            Item Three
          </TabPanel>
        </MuiDialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ViewCustomer;
