import React, { Fragment } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import { yellow } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";

import axios from "axios";

import config from "../../utils/config";
import ViewCustomer from "./ViewCustomer";

import useModal from "../../hooks/use-modal";
import UpdateCustomerInfo from "./UpdateCustomerInfo";
import AlertDialog from "../booking/AlertDialog";

const useStyles = makeStyles((theme) => ({
  update: {
    color: yellow["A200"],
    "&:hover": {
      background: "rgba(255, 253, 231,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const CustomersTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token } = props;

  const [isLoading, setIsLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  const handleCloseAlert = React.useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  const {
    modalState: modalStateEdit,
    modalData: modalDataEdit,
    openHandler: modalOpenEdit,
    closeHandler: modalCloseEdit,
  } = useModal();

  const handleEditSubmit = async (values) => {
    try {
      // appointment services
      await axios.patch(`${config.api}/customers/${values.id}`, values, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setIsLoading(false);
      modalCloseEdit();
      setAlert({
        ...alert,
        open: true,
        message: "Successfully Updated",
        typeAlert: "success",
      });
      tableRef.current && tableRef.current.onQueryChange();
    } catch (error) {
      // Handle error for the first or second axios request
      const errorMessage =
        error.response?.data?.message ||
        "Something went wrong please try again.";

      setIsLoading(false);
      modalCloseEdit();
      setAlert({
        ...alert,
        open: true,
        message: errorMessage,
        typeAlert: "error",
      });
    }
  };

  return (
    <Fragment>
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <ViewCustomer
        data={modalDataView}
        open={modalStateView}
        handleViewClose={modalCloseView}
      />
      <UpdateCustomerInfo
        data={modalDataEdit}
        open={modalStateEdit}
        isLoading={isLoading}
        handleEditClose={modalCloseEdit}
        onSubmit={handleEditSubmit}
      />
      <MaterialTable
        icons={tableIcons}
        title={`Customers Table`}
        tableRef={tableRef}
        columns={[
          { title: "Customer ID", field: "id" },
          {
            title: "Customer Name",
            render: (rowData) =>
              `${rowData.firstname || ""} ${rowData.lastname || ""}`.trim() ||
              "",
          },
          { title: "Phone #", field: "phone" },
          { title: "Email Address", field: "email" },
          { title: "Address", field: "address" },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/customers?";
            url += `&page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "view",
            tooltip: "View",
            onClick: (event, rowData) => {
              modalOpenView(rowData);
            },
          },
          {
            icon: "edit",
            tooltip: "Edit",
            onClick: (event, rowData) => {
              modalOpenEdit(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================View=====================
            if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    aria-label="view"
                    className={classes.view}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Edit=====================
            if (props.action.icon === "edit") {
              return (
                <Tooltip title="edit">
                  <IconButton
                    aria-label="edit"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "4px",
            marginBottom: "-1px",
          },
          pageSize: 20,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
        }}
      />
    </Fragment>
  );
});

export default CustomersTable;
