import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const CustomDaysComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const generateTimeOptions = () => {
    const times = [];
    for (let hour = 7; hour <= 17; hour++) {
      const isPM = hour >= 12;
      const hourIn12HourFormat = hour % 12 === 0 ? 12 : hour % 12; // Convert to 12-hour format
      const amPm = isPM ? "PM" : "AM";

      const timeValue = `${String(hour).padStart(2, "0")}:00:00`; // Value in 24-hour format
      const timeLabel = `${String(hourIn12HourFormat).padStart(
        2,
        "0"
      )}:00 ${amPm}`; // Label in 12-hour format with AM/PM

      times.push({ value: timeValue, label: timeLabel });
    }
    return times;
  };

  const timeOptions = generateTimeOptions();
  return (
    <TextField fullWidth select {...field} {...props}>
      {timeOptions.map((option) => (
        <MenuItemStyle key={option} value={option.value}>
          {option.label}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomDaysComponent;
