import { Fragment } from "react";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  offbutton: {
    backgroundColor: red[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: red[600],
    },
  },
  paper: { maxWidth: "20%" },
  grid: {
    position: "relative",
  },
}));

const Filters = (props) => {
  const { handleOpenAdd, isAddOff } = props;
  const classes = useStyles();
  
  return (
    <Fragment>
      <div style={{ marginBottom: "10px" }}>
        <Grid container spacing={3}>
          <Grid item xs={3}></Grid>
          {!isAddOff && (
            <Grid
              item
              xs={9}
              container
              direction="column"
              alignItems="flex-end"
            >
              <Button
                onClick={handleOpenAdd}
                variant="contained"
                className={classes.button}
                startIcon={<AddIcon />}
              >
                Create Booking slots
              </Button>
            </Grid>
          )}
          {isAddOff && (
            <Grid
              item
              xs={9}
              container
              direction="column"
              alignItems="flex-end"
            >
              <Button
                onClick={handleOpenAdd}
                variant="contained"
                className={classes.offbutton}
                startIcon={<AddIcon />}
              >
                Add Off Dates
              </Button>
            </Grid>
          )}
        </Grid>
      </div>
    </Fragment>
  );
};

export default Filters;
