const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(/^127(?:\.\d{1,3}){3}(:\d+)?$/)
);

export function register() {
  if ("serviceWorker" in navigator) {
    // This will be handled differently for local vs. production environments
    const swUrl = `/firebase-messaging-sw.js`; // Direct reference to the service worker file

    if (process.env.NODE_ENV === "production") {
      // In production, register the service worker
      window.addEventListener("load", () => {
        registerValidSW(swUrl);
      });
    } else if (isLocalhost) {
      // If localhost, check if the service worker is valid
      window.addEventListener("load", () => {
        checkValidServiceWorker(swUrl);
      });
    }
  }
}

function registerValidSW(swUrl) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      console.log(
        "Service Worker registration successful with scope: ",
        registration.scope
      );
    })
    .catch((error) => {
      console.error("Service Worker registration failed: ", error);
    });
}

function checkValidServiceWorker(swUrl) {
  fetch(swUrl)
    .then((response) => {
      if (
        response.status === 404 ||
        response.headers.get("content-type").indexOf("javascript") === -1
      ) {
        // Service worker not found. Possibly a different app, reload.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker exists, proceed with registration.
        registerValidSW(swUrl);
      }
    })
    .catch(() => {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
