import React, { Fragment } from "react";
import MaterialTable from "material-table";
import { tableIcons } from "../booking/TableIcons";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";

import config from "../../utils/config";
import useModal from "../../hooks/use-modal";
import ViewDialog from "../booking/viewDialog/ViewDialog";
import LinkIcon from "@material-ui/icons/Link";

const PendingAppointments = React.memo((props) => {
  const { customerId } = props;

  const token = localStorage.getItem("token");

  // View Dialog
  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  return (
    <Fragment>
      {/* =================View Dialog - (MODAL)================= */}
      <ViewDialog
        data={modalDataView}
        open={modalStateView}
        handleViewClose={modalCloseView}
      />
      <MaterialTable
        icons={tableIcons}
        title={`Pending Appointments`}
        columns={[
          {
            title: "Booking #",
            field: "reference_no",
          },
          { title: "BS Number", field: "bs_number" },
          {
            title: "Vehicle",
            render: (data) => (
              <div>
                {data.make} {data.model} {data.year}
              </div>
            ),
          },
          {
            title: "Service",
            render: (data) => {
              return (
                <div>
                  {data?.appointment_services?.map((service) => (
                    <>
                      {service.service_name} <br />
                    </>
                  ))}
                </div>
              );
            },
          },
          { title: "Amount", field: "total" },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = `${config.api}/customer/appointments/${customerId}?status=1`;
            url += `&page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "view",
            tooltip: "View",
            onClick: (event, rowData) => {
              modalOpenView(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    id="view"
                    aria-label="view"
                    size="small"
                    color="default"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            if (props.action.icon === "linked jo") {
              // =====================Linked JO=====================

              const hasLinkedJO =
                props.data?.appointment_post_dispatch?.length == 0;
              return (
                <Tooltip title="Linked JO">
                  <IconButton
                    id="linked_jo"
                    aria-label="linked JO"
                    size="small"
                    disabled={hasLinkedJO}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <LinkIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "4px",
            marginBottom: "-1px",
          },
          pageSize: 20,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
        }}
      />
    </Fragment>
  );
});

export default PendingAppointments;
