import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  label: {
    fontWeight: 600,
  },
}));

const CustomerInfoForm = ({
  open,
  handleEditClose,
  data,
  onSubmit,
  isLoading,
}) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    id: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    address: "",
  });

  useEffect(() => {
    if (data) {
      setFormValues({
        id: data.id || "",
        firstname: data.firstname || "",
        lastname: data.lastname || "",
        phone: data.phone || "",
        email: data.email || "",
        address: data.address || "",
      });
    }
  }, [data]);

  const validationSchema = Yup.object({
    firstname: Yup.string().required("First name is required"),
    lastname: Yup.string().required("Last name is required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must be digits only")
      .required("Phone number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    address: Yup.string().required("Address is required"),
  });

  return (
    <Dialog open={open} onClose={handleEditClose} maxWidth="sm" fullWidth>
      <Formik
        initialValues={formValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form autoComplete="off">
            <DialogTitle>
              <Typography variant="h6">Edit Customer Info</Typography>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Field
                    label="First Name"
                    as={TextField}
                    name="firstname"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Last Name"
                    as={TextField}
                    name="lastname"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Phone"
                    as={TextField}
                    name="phone"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    label="Email"
                    as={TextField}
                    name="email"
                    type="email"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    label="Address"
                    as={TextField}
                    name="address"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditClose} size="small" color="default">
                Close
              </Button>
              <Button
                disabled={isLoading}
                type="submit"
                color="primary"
                variant="contained"
                size="small"
              >
                {isLoading ? "Submitting" : "Submit"}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default CustomerInfoForm;
