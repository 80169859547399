import { Container, Typography, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
    fontWeight: "bold",
  },
  listItem: {
    marginBottom: theme.spacing(2),
    display: "flex",
    gap: theme.spacing(2),
  },
  number: {
    minWidth: "20px",
    fontWeight: "bold",
  },
}));

export default function InspectionDisclaimer() {
  const classes = useStyles();

  const disclaimerPoints = [
    "The inspection is limited to a visual and functional evaluation of the vehicle's accessible components at the time of the inspection. It does not cover internal systems, hidden defects, or areas requiring disassembly.",
    "The inspection is a preliminary technical assessment conducted using visual, external, and non-mechanical methods only. It does not involve the removal, disassembly, or internal examination of parts, components, assemblies, or mechanisms of the used motor vehicle.",
    "The inspection includes an assessment of the vehicle's mechanical systems, electrical components, equipment, functionality, suspension, undercarriage, brakes, tires, interior, and exterior.",
    "The inspection report is provided solely for informational purposes, reflecting the vehicle's condition and status at the time of inspection. It does not constitute a guarantee, certification, or warranty of the vehicle's condition, value, or future performance.",
    "The inspection report is not a roadworthiness certificate and cannot be used as one.",
    "The buyer is responsible for reviewing and understanding the inspection findings. Any decisions regarding the purchase, repair, or further evaluation of the vehicle are solely the buyer's responsibility.",
    "The inspection does not account for manufacturer recall notices, which remain the sole responsibility of the seller or dealer.",
    "Inspections are subject to the availability of equipment, tools, and access to the vehicle. Some issues may not be identified due to these limitations.",
    "The seller and buyer shall hold Mechanigo.ph free from any liability and is solely responsible for all costs related to the used vehicle, including parts replacement, repairs, shipping, damage compensation, operational expenses, and any other past or future costs.",
    "The road test is not included in the package. However, the mechanic can assist and provide an evaluation during the road test conducted by the buyer and seller.",
  ];

  return (
    <Container maxWidth="md" className={classes.root}>
      <Typography variant="h5" component="h1" className={classes.title}>
        INSPECTION DISCLAIMER
      </Typography>
      <Box>
        {disclaimerPoints.map((point, index) => (
          <Box key={index} className={classes.listItem}>
            <Typography className={classes.number}>{index + 1}.</Typography>
            <Typography>{point}</Typography>
          </Box>
        ))}
      </Box>
    </Container>
  );
}
