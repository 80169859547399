import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const CustomRescheduleReasonComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const reschedule_reason = [
    { value: "Mechanic was late", label: "Mechanic was late" },
    {
      value: "No parts available/wrong parts",
      label: "No parts available/wrong parts",
    },
    { value: "Emergency", label: "Emergency" },
    { value: "Typhoon/Rains", label: "Typhoon/Rains" },
    { value: "No available mechanics", label: "No available mechanics" },
    { value: "No available mechanics", label: "No available mechanics" },
    {
      value: "Unavailable requested technician",
      label: "Unavailable requested technician",
    },
    {
      value: "Supressed demand - no available mechanic for the day",
      label: "Supressed demand - no available mechanic for the day",
    },
    {
      value: "Supressed demand - no engine scanner available",
      label: "Supressed demand - no engine scanner available",
    },
    {
      value: "Supressed demand - timeslot requested not available",
      label: "Supressed demand - timeslot requested not available",
    },
    {
      value:
        "Supressed demand - location is too far from mechanic's 2nd client",
      label:
        "Supressed demand - location is too far from mechanic's 2nd client",
    },
    {
      value:
        "PMS cannot be performed (oil sludge, drain plug loose thread, etc)",
      label:
        "PMS cannot be performed (oil sludge, drain plug loose thread, etc)",
    },
  ];
  return (
    <TextField
      fullWidth
      select
      error={errors.reschedule_reason && touched.reschedule_reason}
      helperText={errors && errors.reschedule_reason}
      {...field}
      {...props}
    >
      {reschedule_reason.map((option) => (
        <MenuItemStyle key={option.value} value={option.value}>
          {option.value}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomRescheduleReasonComponent;
