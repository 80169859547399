import React, { Fragment, useState, useCallback } from "react";
import MaterialTable from "material-table";
import moment from "moment";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import { green, yellow } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import useModal from "../../hooks/use-modal";
import RequestView from "./RequestView";
import RequestUpdateRemarks from "./RequestUpdateRemarks";
import AlertDialog from "../booking/AlertDialog";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import AddIcon from "@material-ui/icons/Add";

// components
import AddQuotationForm from "./components/AddQuotationForm";

// Api config
import config from "../../utils/config";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center !important",
  },
  view: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    color: yellow[600],
    // maxHeight: "2px !important",
    "&:hover": {
      backgroundColor: "rgba(255, 253, 231,1.0)",
    },
  },
  delete: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  add: {
    color: green[600],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const RequestQuotationTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const [addQuotation, setAddQuotation] = useState({
    open: false,
    isLoading: false,
  });

  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  const {
    modalState: modalStateCreate,
    modalData: modalDataCreate,
    openHandler: modalOpenCreate,
    closeHandler: modalCloseCreate,
  } = useModal();

  // =================Remarks Update - (Function)=================
  const handleRemarks = useCallback(
    (values) => {
      let data = values;
      axios
        .put(`${config.api}/quotations/${data.id}`, data, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          modalCloseUpdate();
          setAlert(true);
          setMessage("Successfully Updated");
          setTypeAlert("success");
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          modalCloseUpdate();
          setAlert(true);
          setTypeAlert("error");
          setMessage("Something went wrong please try again.");
          tableRef.current && tableRef.current.onQueryChange();
        });
    },
    [tableRef, token, modalCloseUpdate]
  );

  // =================Quotation Changed Status - (Function)=================
  const handleChange = useCallback(
    (data, event) => {
      let status = event.target.value;
      data.status = status;
      let dataVal = data;

      axios
        .patch(`${config.api}/quotations/status/${data.id}`, dataVal, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAlert(true);
          setMessage("Successfully Updated");
          setTypeAlert("success");
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setAlert(true);
          setTypeAlert("error");
          setMessage("Something went wrong please try again.");
          tableRef.current && tableRef.current.onQueryChange();
        });
    },
    [tableRef, token]
  );

  const handleSubmitQuotation = (data) => {
    console.log("data: ", data);

    let combinedServices = data.services; // this is the packages

    let manualServices = data.manualServices; // this is the services

    if (manualServices.length > 0) {
      let transformedManualServices = manualServices.map((item) => {
        return {
          id: item.id,
          name: item.name,
          remarks: item.remarks,
          quantity: item.quantity,
          price: item.price,
          total: item.totalPrice,
          type: "manual",
        };
      });
      combinedServices = [...combinedServices, ...transformedManualServices];
    }

    delete data.manualServices;
    delete data.manualServicesList;
    delete data.models;
    delete data.servicesList;

    const dataToSave = {
      ...data,
      services: combinedServices,
      sub_total: data.subTotal,
    };

    axios
      .post(`${config.api}/quotations`, dataToSave, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setAddQuotation({ ...addQuotation, open: false, isLoading: false });
        tableRef.current && tableRef.current.onQueryChange();
        setAlert(true);
        setTypeAlert("success");
        setMessage("Successfully added.");
      })
      .catch((error) => {
        setAddQuotation({ ...addQuotation, open: false, isLoading: false });
        setAlert(true);
        setTypeAlert("error");
        setMessage("Something went wrong please try again.");
      })
      .finally(() => modalCloseCreate());
  };

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      {/* =================View - (MODAL)================= */}
      <RequestView
        open={modalStateView}
        data={modalDataView}
        onClose={modalCloseView}
      />

      {/* =================Update Remarks - (MODAL)================= */}
      <RequestUpdateRemarks
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onRemarks={handleRemarks}
      />

      {/* =================Create Quotation - (MODAL)================= */}
      <AddQuotationForm
        open={modalStateCreate}
        data={modalDataCreate}
        onClose={modalCloseCreate}
        handleSubmitQuotation={handleSubmitQuotation}
        isLoading={addQuotation.isLoading}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Quotation Overview`}
        tableRef={tableRef}
        columns={[
          {
            title: "Quotation Date",
            render: (client) => {
              return moment(client.created_at).format("YYYY-MM-DD");
            },
          },
          { title: "Name", field: "name" },
          { title: "Email", field: "email" },
          { title: "Contact#", field: "phone_number" },
          { title: "Address", field: "address" },
          {
            title: "Make/Model",
            render: (client) => {
              return (
                <span style={{ textTransform: "capitalize" }}>
                  {client.make} {client.model}
                </span>
              );
            },
          },
          { title: "Fuel Type", field: "fuel_type" },
          { title: "Services", field: "services" },
          {
            title: "Status",
            render: (client) => {
              let value = client.status ? client.status : "new";
              let defaultVal = value.toLowerCase();
              return (
                <FormControl fullWidth className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(event) => handleChange(client, event)}
                    value={defaultVal}
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <MenuItem value={"new"}>New</MenuItem>
                    <MenuItem value={"quotation sent; replied"}>
                      Quotation Sent; Replied
                    </MenuItem>
                    <MenuItem value={"qoutation sent; no response"}>
                      Quotation Sent; No Response
                    </MenuItem>
                    <MenuItem value={"booked"}>Booked</MenuItem>
                    <MenuItem value={"non-viable"}>Non-viable</MenuItem>
                  </Select>
                </FormControl>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/quotations-requests?";
            url += `&page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "view",
            tooltip: "View",
            onClick: (event, rowData) => {
              modalOpenView(rowData);
            },
          },
          {
            icon: "update",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          {
            icon: "add",
            tooltip: "Create Quotation",
            onClick: (event, rowData) => {
              modalOpenCreate(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================View=====================
            if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    id="view"
                    aria-label="view"
                    size="small"
                    color="default"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            if (props.action.icon === "update") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            if (props.action.icon === "add") {
              return (
                <Tooltip title="Create">
                  <IconButton
                    aria-label="create"
                    className={classes.add}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 20,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          sorting: false,
        }}
      />
    </Fragment>
  );
});

export default RequestQuotationTable;
