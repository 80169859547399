import moment from "moment";
// Function to format date as "month day, year"
export function formatDate(dateStr) {
  const date = new Date(dateStr);
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

// Other helper functions can be defined here
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function capitalizeAllWords(str) {
  if (!str) return "";
  return str
    .split(" ") // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(" "); // Join the words back into a string
}

export const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

export function formatCustomDate(date) {
  const formattedDate = moment(date).format("MMMM D, YYYY h:mma").toUpperCase();
  return formattedDate;
}

export function getGroupCategoryById(id) {
  switch (id) {
    case 1:
    case 4:
    case 5:
    case 6:
      return "pms";
    case 2:
      return "car_buying";
    case 3:
      return "initial_diagnosis";
    default:
      return ""; // return a default value if the ID is not recognized
  }
}
