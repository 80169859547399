import { Fragment } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { green } from "@material-ui/core/colors";
import { Formik, Form, FastField } from "formik";
import { object, string, ref } from "yup";
import { TextField } from "formik-material-ui";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "350px" },
}));

const MechanicAccount = React.memo((props) => {
  const classes = useStyles();

  const { open, onOpen, onClose } = props;

  const handleOpen = () => {
    onOpen();
  };

  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values) => {
    props.handleSubmitAccount(props?.data?.email, values);
  };

  const initialValues = {
    id: props?.data?.id,
    email: (props?.data && props?.data?.email) || "",
    password: (props?.data && props?.data?.password) || "",
  };

  const validationSchema = object().shape({
    email: string()
      .required("Email address is required")
      .email("Must be a valid email"),
    password: string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters long"),
    confirmpassword: string()
      .oneOf([ref("password"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>{"CHANGE PASSWORD"}</span>
                </DialogTitle>
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    {!props?.data?.email && (
                      <Grid item xs={12}>
                        <FastField
                          label="Email Address"
                          component={TextField}
                          name="email"
                          type="email"
                          variant="outlined"
                          size="small"
                          fullWidth
                          inputProps={{
                            style: {
                              textAlign: "center",
                            },
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FastField
                        label="Password"
                        component={TextField}
                        name="password"
                        type="password"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FastField
                        label="Confirm Password"
                        component={TextField}
                        name="confirmpassword"
                        type="password"
                        variant="outlined"
                        size="small"
                        fullWidth
                        inputProps={{
                          style: {
                            textAlign: "center",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} size="small" color="default">
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default MechanicAccount;
