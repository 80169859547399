import { Fragment, useEffect, useState, useCallback } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Dialog,
  DialogContent,
  AppBar,
  IconButton,
  Toolbar,
  Grid,
  Select,
  MenuItem,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { green, red } from "@material-ui/core/colors";
// External
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import axios from "axios";
// Component
import AlertDialog from "../alertDialog/AlertDialog";
import LoadingSpinner from "./LoadingSpinner";
import config from "../../utils/config";

// Set up the localizer for react-big-calendar
const localizer = momentLocalizer(moment);

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  title: {
    color: "rgb(63,81,181) !important",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "100%" },
  card: { marginBottom: "20px" },
  addnewbutton: {
    backgroundColor: red[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: red[600],
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  item: {
    paddingRight: "30px",
    display: "flex",
    alignItems: "center",
  },
  circle: {
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    backgroundColor: "red",
    marginRight: "5px",
  },
  p: {
    fontWeight: "bold",
  },
  circleStyle: {
    borderRadius: "50%",
    width: "22px",
    height: "22px",
    marginRight: "2px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontWeight: "bold",
    color: "#000",
  },
  eventWrapperItem: {
    paddingRight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    boxSizing: "border-box",
  },
}));

const ViewScheduleSlotsDialog = React.memo((props) => {
  const { data, open, handleClose } = props;
  const classes = useStyles();

  const [events, setEvents] = useState([]);
  const [view, setView] = useState("month");
  const [loading, setLoading] = useState(false);
  const [viewData, setViewData] = useState("PS");

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  // Using useCallback to avoid re-creating functions
  const fetchScheduleSlots = useCallback(async (param) => {
    try {
      setLoading(true);
      let month = moment().format("MMMYYYY");
      if (param?.month) {
        month = param.month;
      }
      const {
        data: { data },
      } = await axios.get(
        `${config.api}/available-appointment-slots?month=${month}&garage_name=${param?.garage?.name}`
      );
      let slots = [];
      if (data?.slots?.length > 0) {
        data?.slots?.forEach((slot) => {
          slot?.slots?.forEach((innerSlot) =>
            innerSlot.month_dates?.forEach((item) => {
              slots.push({
                title: `Available`,
                start: new Date(item.start),
                end: new Date(item.end),
                allDay: false,
                slot_caps: item.available_slots_cap,
                pms: item.available_slots_pms,
                car_buying: item.available_slots_car_buying,
                initial_diagnosis: item.available_slots_initial_diagnosis,
              });
            })
          );
        });
      }

      let offdates = [];
      if (data?.offdays?.length > 0) {
        data?.offdays.forEach((day) => {
          offdates.push({
            title: "OFF day",
            description: day.reason,
            allDay: true,
            start: new Date(day.date),
            end: new Date(day.date),
          });
        });
      }
      setEvents((prevEvents) => {
        return [...prevEvents, ...slots, ...offdates];
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAlert({
        ...alert,
        open: true,
        message: "Something went wrong please try again.",
        typeAlert: "error",
      });
    }
  }, []);

  // UseEffect to fetch data only when necessary
  useEffect(() => {
    if (data && open) {
      fetchScheduleSlots(data);
    }
  }, [data, open, fetchScheduleSlots]);

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  const handleNavigate = useCallback(
    (newDate) => {
      const currentMonth = moment().format("MMMYYYY"); // Get current month in the format "MMMYYYY"
      const newMonth = moment(newDate).format("MMMYYYY"); // Format the newDate to match the format
      // Only proceed if the new date is not the current month
      if (newMonth !== currentMonth) {
        const payload = { ...data, month: newMonth };
        fetchScheduleSlots(payload);
      }
    },
    [data, fetchScheduleSlots]
  );

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleDialogClose = () => {
    setEvents([]);
    handleClose();
  };

  const Event = React.memo(({ event }) => {
    const startTime = moment(event?.start).format("hh:mmA");
    const endTime = moment(event?.end).format("hh:mmA");
    return (
      <div
        style={{
          height: "100%",
        }}
      >
        {event?.allDay ? (
          <>
            <p style={{ margin: "0px", color: "#000", padding: "0px" }}>
              {event?.title}
            </p>
            <small style={{ color: "#000", padding: "0px" }}>
              {`${startTime} - ${endTime}`}
            </small>
          </>
        ) : (
          <>
            {viewData === "PC" && (
              <div className={classes.eventWrapperItem}>
                <small
                  style={{ color: "#000", paddingRight: "3px" }}
                >{`${startTime} - ${endTime}`}</small>
                <div
                  className={classes.circleStyle}
                  style={{ backgroundColor: "#50C878" }}
                >
                  {event.slot_caps}
                </div>
              </div>
            )}
            {viewData === "PS" && (
              <div className={classes.eventWrapperItem}>
                <small
                  style={{ color: "#000", paddingRight: "3px" }}
                >{`${startTime} - ${endTime}`}</small>
                <div
                  className={classes.circleStyle}
                  style={{ backgroundColor: "#6495ED" }}
                >
                  {event.pms}
                </div>
                <div
                  className={classes.circleStyle}
                  style={{ backgroundColor: "#00FF00" }}
                >
                  {event.car_buying}
                </div>
                <div
                  className={classes.circleStyle}
                  style={{ backgroundColor: "#BF40BF" }}
                >
                  {event.initial_diagnosis}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  });

  const eventStyleGetter = (event) => {
    let backgroundColor = "transparent";
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        padding: "2px 10px",
        minHeight: "25px",
      },
    };
  };

  const allDayDates = new Set(
    events
      .filter((event) => event.allDay)
      .map((event) => event.start.toDateString())
  );

  const filteredEvents = events.filter((event) => {
    const isAllDayOrNotOnAllDayDate =
      event.allDay || !allDayDates.has(event.start.toDateString());
    return isAllDayOrNotOnAllDayDate;
  });

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.paper }}
        fullScreen
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDialogClose}
              aria-label="close"
            >
              <CloseIcon className={classes.button} />
            </IconButton>
            <Typography variant="h5" className={classes.title}>
              {`SCHEDULE SLOTS - ${data?.garage?.name}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent dividers>
          <LoadingSpinner loading={loading} />
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <div className={classes.wrapper}>
                <div className={classes.item}>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: "#00FF00" }}
                  />
                  <p className={classes.p}>Secondhand car inspection</p>
                </div>
                <div className={classes.item}>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: "#6495ED" }}
                  />
                  <p className={classes.p}>PMS oil change</p>
                </div>
                <div className={classes.item}>
                  <div
                    className={classes.circle}
                    style={{ backgroundColor: "#BF40BF" }}
                  />
                  <p className={classes.p}>Initial diagnosis</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Select
                value={viewData}
                onChange={(e) => setViewData(e.target.value)}
                displayEmpty
                fullWidth
              >
                <MenuItem value="PS">Per Service</MenuItem>
                <MenuItem value="PC">Per Cap Slots</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <div style={{ height: "80vh" }}>
            <Calendar
              defaultView="month"
              onView={handleViewChange}
              localizer={localizer}
              events={filteredEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: "100%" }}
              components={{
                event: Event, // Use the custom event component to display time
              }}
              eventPropGetter={eventStyleGetter}
              onNavigate={handleNavigate}
              popup={false}
              step={60}
            />
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
});

export default ViewScheduleSlotsDialog;
