import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import MaterialTable from "material-table";
import { useSelector } from "react-redux";
import axios from "axios";
// mui
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AddAlarmIcon from "@material-ui/icons/AddAlarm";
import { makeStyles } from "@material-ui/core/styles";
import { green, yellow } from "@material-ui/core/colors";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
// api config
import config from "../../utils/config";

import useModal from "../../hooks/use-modal";

import UpdateAttendance from "./UpdateAttendance";
import AddOvertime from "./AddOvertime";
import AlertDialog from "../alertDialog/AlertDialog";
import Filters from "./Filters";
import ViewAttendance from "./ViewAttendance";

const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#000",
    },
  },
  update: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#000",
    },
  },
  dashboard: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
  root: {
    textAlign: "center !important",
  },
}));

const Attendances = (props) => {
  const { token, mechanics } = props;

  const tableRef = useRef();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const attendanceFilter = useSelector((state) => state.attendanceFilter);

  useEffect(() => {
    if (attendanceFilter) {
      tableRef.current.onChangePage({}, 0);
    }
    return () => {};
  }, [tableRef, attendanceFilter]);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  // View Dialog
  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  // Update Dialog
  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  // Add Overtime
  const {
    modalState: modalStateAddOvetime,
    modalData: modalDataAddOvetime,
    openHandler: modalOpenAddOvetime,
    closeHandler: modalCloseAddOvetime,
  } = useModal();

  const handleUpdateSubmit = useCallback(
    (data) => {
      setLoading(true);
      try {
        if (!data?.id) {
          // add
          const payload = {
            mechanic_id: data?.mechanic_id,
            time_in: moment
              .tz(data?.time_in, "Asia/Manila")
              .format("YYYY-MM-DD hh:mm a"),
            time_out: moment
              .tz(data?.time_out, "Asia/Manila")
              .format("YYYY-MM-DD hh:mm a"),
            source: "web",
            status: "Pending",
          };
          axios.post(
            `${config.api}/mechanics/${data?.mechanic_id}/attendances`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setLoading(false);
          modalCloseUpdate();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully added",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        } else {
          // update
          const payload = {
            ...modalDataUpdate,
            time_in: moment
              .tz(data?.time_in, "Asia/Manila")
              .format("YYYY-MM-DD hh:mm a"),
            time_out: moment
              .tz(data?.time_out, "Asia/Manila")
              .format("YYYY-MM-DD hh:mm a"),
          };
          axios.put(
            `${config.api}/mechanics/${data?.mechanic_id}/attendances/${data?.id}`,
            payload,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setLoading(false);
          modalCloseUpdate();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully updated",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        }
      } catch (error) {
        setLoading(false);
        modalCloseUpdate();
        setAlert({
          ...alert,
          open: true,
          message: "Something went wrong please try again.",
          typeAlert: "error",
        });
      }
    },
    [tableRef, token]
  );

  const handleChange = useCallback(
    (data, event) => {
      try {
        setLoading(true);
        const payload = data;
        payload.status = event.target.value;
        axios.put(
          `${config.api}/mechanics/${data?.mechanic_id}/attendances/${data?.id}`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setLoading(false);
        setAlert({
          ...alert,
          open: true,
          message: "Successfully added",
          typeAlert: "success",
        });
        tableRef.current && tableRef.current.onQueryChange();
      } catch (error) {
        setLoading(false);
        setAlert({
          ...alert,
          open: true,
          message: "Something went wrong please try again.",
          typeAlert: "error",
        });
      }
    },
    [tableRef, token]
  );

  // Add mechanic overtime
  const handleAddSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);

        const time_from = moment
          .tz(data?.time_from, "Asia/Manila")
          .format("hh:mm a");

        const time_to = moment
          .tz(data?.time_to, "Asia/Manila")
          .format("hh:mm a");

        const date_from = moment(
          `${data?.time_in} ${time_from}`,
          "YYYY-MM-DD hh:mm a"
        ).format("YYYY-MM-DD hh:mm a");
        const date_to = moment(
          `${data?.time_in} ${time_to}`,
          "YYYY-MM-DD hh:mm a"
        ).format("YYYY-MM-DD hh:mm a");

        if (!date_from || !date_to) {
          throw new Error("Invalid date values");
        }

        const payload = {
          date_from,
          date_to,
          reason: data?.reason,
          source: "web",
          status: "Pending",
        };

        const response = await axios.post(
          `${config.api}/mechanics/${data?.mechanic_id}/overtimes`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        // Check if the response is successful (status code 2xx)
        if (response.status >= 200 && response.status < 300) {
          setAlert({
            ...alert,
            open: true,
            message: "Successfully requested",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        } else {
          // Handle non-2xx responses, if needed
          throw new Error("Server returned an error: " + response.statusText);
        }
      } catch (error) {
        setLoading(false);
        modalCloseAddOvetime();
        // Handle the error message more specifically
        const errorMessage = error.response
          ? error.response.data?.message ||
            "Something went wrong. Please try again."
          : error.message || "Unknown error occurred.";

        setAlert({
          ...alert,
          open: true,
          message: errorMessage,
          typeAlert: "error",
        });
      } finally {
        setLoading(false); // Always set loading to false after the request
        modalCloseAddOvetime();
      }
    },
    [alert, tableRef, token]
  );

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      {/* =================Update Dialog - (MODAL)================= */}
      <UpdateAttendance
        token={token}
        data={modalDataUpdate}
        loading={loading}
        open={modalStateUpdate}
        handleUpdateSubmit={handleUpdateSubmit}
        handleUpdateClose={modalCloseUpdate}
        handleOpen={modalOpenUpdate}
        mechanics={mechanics}
      />
      {/* =================View Dialog - (MODAL)================= */}
      <ViewAttendance
        data={modalDataView}
        open={modalStateView}
        handleViewClose={modalCloseView}
      />
      {/* =================Add Overtime Dialog - (MODAL)================= */}
      <AddOvertime
        data={modalDataAddOvetime}
        open={modalStateAddOvetime}
        handleAddClose={modalCloseAddOvetime}
        handleAddSubmit={handleAddSubmit}
      />
      <Filters handleOpen={modalOpenUpdate} isAddAttendance />
      <MaterialTable
        title="Mechanics Attendances"
        tableRef={tableRef}
        columns={[
          {
            title: "Date",
            render: (row) => {
              return moment(row?.time_in).format("YYYY-MM-DD");
            },
          },
          {
            title: "Mechanic",
            render: (row) => {
              return (
                <p>{`${row.mechanic.firstname}  ${row.mechanic.lastname}`}</p>
              );
            },
          },
          {
            title: "Time In",
            render: (row) => {
              return <p>{moment(row?.time_in).format("hh:mm A")}</p>;
            },
          },
          {
            title: "Time Out",
            render: (row) => {
              if (!row?.time_out) return;
              return <p>{moment(row?.time_out).format("hh:mm A")}</p>;
            },
          },
          {
            title: "Worked Hours",
            field: "working_hours",
          },
          {
            title: "Source",
            field: "source",
          },
          {
            title: "Status",
            render: (row) => {
              return (
                <FormControl fullWidth>
                  <Select
                    id="simple-select"
                    onChange={(event) => handleChange(row, event)}
                    value={row.status}
                    classes={{
                      root: classes.root,
                    }}
                  >
                    <MenuItem value="Pending">Pending</MenuItem>
                    <MenuItem value="Approved">Approved</MenuItem>
                  </Select>
                </FormControl>
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/mechanics/attendances?";
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            if (attendanceFilter.date_from && attendanceFilter.date_to) {
              const dateStart = moment(attendanceFilter.date_from).format(
                "YYYY-MM-DD"
              );
              const notToday = dateStart != moment().format("YYYY-MM-DD");
              if (notToday) {
                url += `&date_from=${moment(attendanceFilter.date_from).format(
                  "YYYY-MM-DD"
                )}&date_to=${moment(attendanceFilter.date_to).format(
                  "YYYY-MM-DD"
                )}`;
              }
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result?.data?.data,
                  page: result?.data?.current_page - 1 || 0,
                  totalCount: result?.data?.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "edit",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          {
            icon: "view",
            tooltip: "View",
            onClick: (event, rowData) => {
              modalOpenView(rowData);
            },
          },
          {
            icon: "add",
            tooltip: "Add ovetime",
            onClick: (event, rowData) => {
              modalOpenAddOvetime(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Edit=====================
            if (props.action.icon === "edit") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.view}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================View=====================
            if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    id="view"
                    aria-label="view"
                    size="small"
                    color="default"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Add ovetime=====================
            if (props.action.icon === "add") {
              return (
                <Tooltip title="Add Overtime">
                  <IconButton
                    id="add"
                    aria-label="add"
                    size="small"
                    color="primary"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <AddAlarmIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
        }}
      />
    </Fragment>
  );
};

export default Attendances;
