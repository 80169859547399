import { Fragment, useState, useEffect } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Box,
} from "@material-ui/core";
import { CheckCircle, Add, RemoveCircle } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Formik, Form, FastField, FieldArray } from "formik";
import * as Yup from "yup";
import { DatePicker as ReactDatepicker } from "formik-material-ui-pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "100%" },
  card: { marginBottom: "20px" },
  addnewbutton: {
    backgroundColor: red[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: red[600],
    },
  },
}));

const defaultValues = {
  offdates: [
    {
      date: new Date(),
      reason: "",
    },
  ],
};

const AddAppointmenOffDates = React.memo((props) => {
  const classes = useStyles();
  const { data, handleClose, open, handleSubmitOffDates } = props;

  const [initialValues, setInitialValues] = useState(defaultValues);

  const handleOnSubmit = (values) => {
    handleSubmitOffDates(values);
  };

  // Yup Validation Schema
  const validationSchema = Yup.object({
    offdates: Yup.array()
      .of(
        Yup.object({
          date: Yup.date()
            .required("Date is required")
            .test(
              "is-valid-date",
              "Invalid date format, expected yyyy-MM-dd",
              (value) => {
                // Check if the value is valid and matches the required format
                const datePattern = /^\d{4}-\d{2}-\d{2}$/; // yyyy-MM-dd regex
                return value
                  ? datePattern.test(value.toISOString().split("T")[0])
                  : true;
              }
            ),
          reason: Yup.string().required("Reason is required"),
        })
      )
      .required("Must have at least one off dates")
      .min(1, "Must have at least one off dates"),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
          fullScreen
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD/UPDATE APPOINTMENT OFF DATES"}
                  </span>
                </DialogTitle>
                <input type="hidden" name="id" />
                <DialogContent dividers>
                  <Typography variant="h5">Off Dates</Typography>
                  <FieldArray name="offdates">
                    {({ insert, remove, push }) => (
                      <>
                        {values.offdates.map((offday, index) => (
                          <Card className={classes.card} key={index}>
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item xs={3}>
                                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <FastField
                                      fullWidth
                                      label="Date"
                                      minDate={new Date()}
                                      component={ReactDatepicker}
                                      format="yyyy-MM-dd"
                                      size="small"
                                      inputVariant="outlined"
                                      name={`offdates[${index}].date`}
                                      variant="inline"
                                      autoOk
                                      inputProps={{
                                        style: { textAlign: "center" },
                                      }}
                                      error={
                                        touched.offdates?.[index]?.date &&
                                        !!errors.offdates?.[index]?.date
                                      }
                                      helperText={
                                        touched.offdates?.[index]?.date &&
                                        errors.offdates?.[index]?.date
                                      }
                                    />
                                  </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={7}>
                                  <FastField
                                    fullWidth
                                    name={`offdates[${index}].reason`}
                                    component={TextField}
                                    label="Reason"
                                    size="small"
                                    variant="outlined"
                                    value={offday.reason}
                                    error={
                                      touched.offdates?.[index]?.reason &&
                                      !!errors.offdates?.[index]?.reason
                                    }
                                    helperText={
                                      touched.offdates?.[index]?.reason &&
                                      errors.offdates?.[index]?.reason
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `offdates[${index}].reason`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <Button
                                    onClick={() => remove(index)}
                                    size="small"
                                    style={{ color: "red" }}
                                    fullWidth
                                    startIcon={<RemoveCircle size="0.9rem" />}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        ))}
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <Button
                              onClick={() =>
                                push({
                                  date: "",
                                  reason: "",
                                })
                              }
                              size="small"
                              color="primary"
                              variant="contained"
                              fullWidth
                              className={classes.addnewbutton}
                              startIcon={<Add size="0.9rem" />}
                            >
                              Add Off Date
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <DialogActions>
                    <Button onClick={handleClose} size="small" color="default">
                      Close
                    </Button>
                    <Button
                      disabled={isSubmitting}
                      type="submit"
                      color="primary"
                      variant="contained"
                      autoFocus
                      size="small"
                      endIcon={
                        isSubmitting ? <CheckCircle size="0.9rem" /> : undefined
                      }
                    >
                      {isSubmitting ? "Submitting" : "Save"}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});

export default AddAppointmenOffDates;
