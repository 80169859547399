import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import axios from "axios";
import config from "../../utils/config";

const SelectCustomerType = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [customerTypes, setCustomerTypes] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    async function fetchData() {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const {
        data: { data },
      } = await axios.get(`${config.api}/customer-types`, options);
      setCustomerTypes(data);
    }
    fetchData();
  }, []);

  return (
    <Autocomplete
      id="customer_type"
      size="small"
      options={customerTypes}
      getOptionLabel={(option) => option.name}
      onChange={(event, value) => {
        setFieldValue("customer_type", value);
      }}
      value={values.customer_type}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Customer Type"
          variant="outlined"
          error={errors.customer_type && touched.customer_type}
          helperText={
            errors.customer_type &&
            touched.customer_type &&
            errors.customer_type
          }
        />
      )}
    />
  );
};
export default SelectCustomerType;
