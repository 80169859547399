import React from "react";
const CustomDiscountComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const formatNumberWithCommasAndPeso = (number) => {
    // If there's no value, return empty string (no peso sign)
    if (!number) return "";
    return `₱${new Intl.NumberFormat().format(number)}`;
  };

  return (
    <React.Fragment>
      <div style={{ textAlign: "right" }}>
        <div>
          <input
            {...props}
            {...field}
            value={
              field.value ? formatNumberWithCommasAndPeso(field.value) : ""
            }
            style={{
              outline: 0,
              borderWidth: "0",
              textAlign: "right",
            }}
            onChange={(event) => {
              let value = event.target.value;

              // Remove non-numeric characters
              let numbersOnly = value.replace(/\D/g, "");

              // Limit input to 6 digits
              if (numbersOnly.length > 6) {
                numbersOnly = numbersOnly.slice(0, 6);
              }

              setFieldValue("discounts", numbersOnly);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default CustomDiscountComponent;
