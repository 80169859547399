import React, {
  Fragment,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import MaterialTable from "material-table";
import axios from "axios";
// mui
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { green, red } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
import moment from "moment";
// api config
import config from "../../utils/config";
import useModal from "../../hooks/use-modal";
// Components
import AlertDialog from "../alertDialog/AlertDialog";
import DeleteDialog from "./DeleteDialog";
import Filters from "./Filters";
import AddAppointmentOffDates from "./AddAppointmentOffDates";

const useStyles = makeStyles((theme) => ({
  delete: {
    backgroundColor: red["A200"],
    color: "#FFF",
    "&:hover": {
      color: "#000",
    },
  },
  update: {
    backgroundColor: green["500"],
    color: "#FFF",
    "&:hover": {
      color: "#000",
    },
    marginRight: "10px",
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
  root: {
    textAlign: "center !important",
  },
  red: {
    color: red["500"],
  },
}));

const OffDaysTable = (props) => {
  const { token } = props;
  const tableRef = useRef();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  // Add Dialog
  const {
    modalState: modalStateAdd,
    modalData: modalDataAdd,
    openHandler: modalOpenAdd,
    closeHandler: modalCloseAdd,
  } = useModal();

  // Delete Dialog
  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  const handleSubmitOffDates = useCallback(async (data) => {
    try {
      setLoading(true);
      await axios.post(`${config.api}/appointment-offdates`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAlert({
        ...alert,
        open: true,
        message: "Successfully added",
        typeAlert: "success",
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Extract validation errors
        const validationErrors = error.response.data.errors;
        let formattedErrors = "";
        for (const key in validationErrors) {
          if (validationErrors[key].length > 0) {
            formattedErrors += validationErrors[key].join(" ") + " ";
          }
        }
        // Optionally display them in the UI, e.g., using a toast or error message component
        setAlert({
          ...alert,
          open: true,
          message: formattedErrors.trim(),
          typeAlert: "error",
        });
      } else {
        console.error("Error occurred:", error); // Other errors (network issues, etc.)
      }
    } finally {
      setLoading(false);
      modalCloseAdd();
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, []);

  const handleDelete = useCallback(
    (id) => {
      setLoading(true);
      axios
        .delete(`${config.api}/appointment-offdates/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully deleted",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setLoading(false);
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseDelete, tableRef, token]
  );

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
      {/* =================Add Appointment Off Dates Dialog - (MODAL)================= */}
      <AddAppointmentOffDates
        open={modalStateAdd}
        handleClose={modalCloseAdd}
        handleSubmitOffDates={handleSubmitOffDates}
      />
      {/* =================Delete Dialog- (MODAL)================= */}
      <DeleteDialog
        data={modalDataDelete}
        isLoading={loading}
        open={modalStateDelete}
        handleDelete={handleDelete}
        handleDeleteClose={modalCloseDelete}
      />
      <Filters isAddOff handleOpenAdd={modalOpenAdd} />
      <MaterialTable
        title="Off Days"
        tableRef={tableRef}
        columns={[
          {
            title: "Date",
            render: (row) => {
              if (moment(row?.date).isBefore(moment(), "day")) {
                return (
                  <p className={classes.red}>
                    {moment(row?.date).format("dddd, MMMM Do YYYY")}
                  </p>
                );
              }
              return (
                <p>
                  <strong>
                    {moment(row?.date).format("dddd, MMMM Do YYYY")}
                  </strong>
                </p>
              );
            },
          },
          {
            title: "Reason",
            render: (row) => {
              return <p>{row.reason}</p>;
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/appointment-offdates?";
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                console.log(result);
                resolve({
                  data: result?.data?.data,
                  page: result?.data?.current_page - 1 || 0,
                  totalCount: result?.data?.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "delete",
            tooltip: "delete",
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    id="delete"
                    aria-label="delete"
                    className={classes.delete}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
          search: false,
        }}
      />
    </Fragment>
  );
};

export default OffDaysTable;
