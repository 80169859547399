import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import "../../../styles/print.css";
import "moment-timezone";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Box,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  PaymentType,
  PaymentMethod,
  paymentStatusTypes,
} from "../../../utils/constant";
import { formatCustomDate } from "../../../utils/helper";
import "./print.css";

// Api config
import config from "../../../utils/config";
import { numberWithCommas } from "../../../utils/accounting";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    marginTop: "1rem",
    fontFamily: "Arial, sans-serif",
    "& p": {
      fontFamily: "Arial, sans-serif",
    },
  },
  joTitle: {
    padding: "2px",
    fontSize: "18px",
    color: "#fff",
    background: "#1f2368",
    paddingLeft: "1rem",
    marginRight: "-30px",
    fontWeight: 700,
  },
  makeDetails: {
    fontWeight: "bold",
    fontSize: "16px",
    color: "#1f2368",
    padding: 0,
    lineHeight: 1,
  },
  customerDetails: {
    fontSize: "10px",
    color: "#1f2368",
    padding: 0,
    fontWeight: "bold",
    textTransform: "uppercase",
    "& .MuiListItemText-root": {
      margin: "4px 0 0 0",
    },
    "& .MuiListItemText-primary": {
      fontWeight: "bold",
      fontSize: "10px",
    },
    "& .MuiListItemText-secondary": {
      // fontWeight: "bold",
      textAlign: "right",
      color: "#000",
      fontSize: "10px",
    },
  },
  appointmentDetails: {
    fontSize: "10px",
    fontWeight: "bold",
    color: "#1f2368",
    padding: 0,
    justifyContent: "flex-end",
    "& .MuiListItem-root": {
      fontWeight: "bold",
      textAlign: "right",
      color: "#000",
      fontSize: "10px",
    },
  },
  table: {
    fontFamily: "Arial, sans-serif",
    border: "1px solid #1f2368",
  },
  tableHead: {
    background: "#1f2368",
  },
  tableTH: {
    fontFamily: "Arial, sans-serif",
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
    padding: "2px",
  },
  tableListHead: {
    fontFamily: "Arial, sans-serif",
    textAlign: "center",
    fontSize: "10px",
    fontWeight: "bold",
  },
  tableCell: {
    fontFamily: "Arial, sans-serif",
    fontSize: "10px",
    padding: "4px 10px",
    fontWeight: "bold",
    textAlign: "center",
    borderBottom: "unset",
    borderRight: "1px solid #1f2368",
  },
  paymentDetailsTitle: {
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "1.5",
    background: "#1f2368",
    color: "#fff",
    // padding: "2px",
    width: "100%",
    marginBottom: "0.5rem",
    padding: "0 6px",
  },
  paymentDetails: {
    fontWeight: "bold",
    color: "#1f2368",
    fontSize: "10px",
    padding: "0",
    "& .MuiListItemText-root": {
      margin: 0,
    },
    "& .MuiListItemText-primary": {
      fontFamily: "Arial, sans-serif",
      fontWeight: "bold",
      fontSize: "10px",
    },
    "& .MuiListItemText-secondary": {
      fontWeight: "bold",
      padding: 0,
      textAlign: "right",
      color: "#000",
      textTransform: "uppercase",
      fontSize: "10px",
    },
  },
  computationTitle: {
    fontWeight: "bold",
    fontSize: "10px",
    lineHeight: "1.5",
    background: "#1f2368",
    color: "#fff",
    padding: "0 6px",
    // padding: "0 6px 0 16px",
    marginTop: "6px",
    width: "100%",
    "& .MuiListItemText-root": {
      margin: 0,
    },
    "& .MuiListItemText-primary": {
      fontFamily: "Arial, sans-serif",
      fontWeight: "bold",
      fontSize: "10px",
    },
    "& .MuiListItemText-secondary": {
      fontWeight: "bold",
      padding: 0,
      textAlign: "right",
      color: "#FFF",
      textTransform: "uppercase",
      fontSize: "10px",
    },
  },
  computation: {
    fontWeight: "bold",
    color: "#1f2368",
    fontSize: "10px",
    borderBottom: "1px solid #1f2368",
    textTransform: "uppercase",
    padding: "2px",
    "& .MuiListItemText-root": {
      margin: 0,
    },
    "& .MuiListItemText-primary": {
      fontFamily: "Arial, sans-serif",
      fontWeight: "bold",
      fontSize: "10px",
    },
    "& .MuiListItemText-secondary": {
      fontWeight: "bold",
      padding: 0,
      paddingRight: "4px",
      textAlign: "right",
      color: "#000",
      textTransform: "uppercase",
      fontSize: "10px",
    },
  },
  notes: {
    margin: ".08rem  0",
    fontWeight: 700,
    fontSize: "10px",
    color: "#1f2368",
  },
  total: {
    textAlign: "right",
  },
  technician: {
    color: " #fff",
    background: "#1f2368",
    fontWeight: 700,
    padding: "0 6px",
    fontSize: "10px",
    marginBottom: "2rem",
    border: "1px solid #1f2368",
  },
  cards: {
    borderRadius: 0,
  },
  lemonBusterLogo: {
    width: "300px",
    height: "50px",
  },
}));

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const displayAddress = (data) => {
  if (data.barangay && data.municipality && data.province) {
    return `${data.address}, ${data.barangay}, ${data.municipality}, ${
      data.province
    }, ${data.zip_code || ""}`;
  }
  return data.address;
};

export const pluck = (arr, key) => arr.map((i) => i[key]);

const CustomerCopy = React.memo((props) => {
  const { data, token, customerCopy } = props;

  const classes = useStyles();

  const [appointmentData, setAppointmentData] = useState(null);

  const isCarBuying = () => {
    if (!appointmentData) return false;
    const count = appointmentData.appointment_services.length;
    const isOnlyCarBuying = appointmentData.appointment_services.find(
      (appt) => appt.package_id === 25
    );

    if (count === 1 && isOnlyCarBuying) return true;
    return false;
  };

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      async function fetchData() {
        const response = await axios.get(
          `${config.api}/appointment/details?id=${data.id}`,
          options
        );

        setAppointmentData(response.data.data);
        return response.data.data;
      }

      fetchData();
    }
  }, [data, token]);

  const ListItemData = (data) => {
    return (
      <ListItem
        className={classes.customerDetails}
        style={{
          borderBottom: "1px solid #1f2638",
        }}
      >
        <ListItemText
          className={classes.customerDetails}
          primary={data.title}
        />
        <ListItemText
          className={classes.customerDetails}
          secondary={data.details}
        />
      </ListItem>
    );
  };
  const paymentReference = (payments, type) => {
    if (!payments) return "";

    return payments.length > 0
      ? payments.map((payment, index) => (
          <ListItem key={index} className={classes.paymentDetails}>
            <ListItemText primary="PAYMENT REF. NO." />
            <ListItemText secondary={payment.payment_ref} />
          </ListItem>
        ))
      : "";
  };

  const additionalFees = (fees) => {
    if (!fees) return "";

    return fees.length > 0
      ? fees.map((fee) => (
          <ListItem key={fee.id} className={classes.computation}>
            <ListItemText primary={fee.fee_type.name} />
            <ListItemText secondary={fee.amount} />
          </ListItem>
        ))
      : "";
  };

  const paymentType = (payments) => {
    if (!payments) return "";
    const filteredPayments = payments.filter(
      (payment) => payment.payment_type !== null && payment.payment_type !== ""
    );
    if (filteredPayments.length > 0) {
      return filteredPayments.map((payment) => {
        return (
          <ListItem className={classes.computation} key={payment.id}>
            <ListItemText
              primary={PaymentType[payment.payment_type] || ""}
              style={{ color: "red" }}
            />
            <ListItemText
              primary={numberConverter(payment.amount_paid)}
              style={{ color: "red", textAlign: "right" }}
            />
          </ListItem>
        );
      });
    } else {
      return "";
    }
  };

  const renderPaymentStatus = (paymentStatus) => {
    if (!paymentStatus) return "";
    const paymentInfo = paymentStatusTypes[paymentStatus?.name];
    if (paymentInfo?.text) return paymentInfo.text;
    return "";
  };

  const renderServiceType = (packages) => {
    if (!packages || packages?.length === 0) return "";
    const serviceType = packages[0]?.package?.package_category?.name || "";
    return serviceType;
  };

  const hasPreDispatchPackage = appointmentData?.packages?.some(
    (ad) => !ad.appointment_post_dispatch_id
  );

  const hasPreDispatchService = appointmentData?.services?.some(
    (ad) => !ad.appointment_post_dispatch_id
  );

  const hasPreDispatchPackageAddOns =
    appointmentData?.appointment_package_add_ons?.some(
      (ad) => !ad.appointment_post_dispatch_id
    );

  const hasPreDispatchParts = appointmentData?.service_parts?.some(
    (ad) => !ad.appointment_post_dispatch_id
  );

  const hasPreDispatchTires = appointmentData?.service_tires?.some(
    (ad) => !ad.appointment_post_dispatch_id
  );

  const totalReservationMade =
    data?.payment?.length > 0
      ? data.payment
          .filter((p) => p.payment_type !== null)
          .reduce(
            (total, payment) => total + parseFloat(payment.amount_paid),
            0
          )
      : 0;

  const totalAdditionalFees =
    data?.appointment_fees?.length > 0
      ? data.appointment_fees.reduce(
          (total, fees) => total + parseFloat(fees.amount),
          0
        )
      : 0;

  return (
    <Fragment>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <img src={"/images/mechanigo_logo_280x60.png"} alt="printLogo" />
          </Grid>
          <Grid item xs={6}>
            <Typography className={classes.joTitle}>
              JOB ORDER/BILLING STATEMENT
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingBottom: 0 }}>
            <List>
              <ListItem className={classes.makeDetails}>
                {data.plate_number}
              </ListItem>
              <ListItem className={classes.makeDetails}>
                {data.make} {data.model} {data.year}
              </ListItem>
              <ListItem
                className={classes.makeDetails}
                style={{ fontSize: "10px", marginBottom: "0.5rem" }}
              >
                {data.fuel_type} {data.engine_size} {data.transmission}
              </ListItem>
              <ListItemData
                title="CUSTOMER"
                details={`${data?.name} ${data.lastname}`}
              />
              <ListItemData
                title="CUSTOMER ID"
                details={`${data.customer_id}`}
              />
              <ListItemData title="CONTACT NO." details={data?.phone} />
              {isCarBuying() && (
                <>
                  <ListItemData
                    title="SELLER TYPE"
                    details={
                      data?.seller_type?.name ? data?.seller_type?.name : ""
                    }
                  />
                  <ListItemData
                    title="SELLER NAME"
                    details={data?.seller_name}
                  />
                </>
              )}
              <ListItemData
                title={isCarBuying() ? "SELLER ADDRESS" : "ADDRESS"}
                details={displayAddress(data)}
              />
              <ListItemData title="LANDMARKS" details={data?.landmarks} />
              {(data.billing_company_name ||
                data.billing_company_address ||
                data.billing_company_tin) && (
                <>
                  {data.billing_company_name && (
                    <ListItemData
                      title="COMPANY NAME"
                      details={data.billing_company_name}
                    />
                  )}
                  {data.billing_company_address && (
                    <ListItemData
                      title="COMPANY ADDRESS"
                      details={data.billing_company_address}
                    />
                  )}
                  {data.billing_company_tin && (
                    <ListItemData
                      title="COMPANY TIN"
                      details={data.billing_company_tin}
                    />
                  )}
                </>
              )}
            </List>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={4}>
            <List>
              <Grid container>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <ListItem
                    className={classes.appointmentDetails}
                    style={{ justifyContent: "end" }}
                  >
                    {formatCustomDate(data.appointment_date)}
                  </ListItem>
                </Grid>
              </Grid>
              <ListItemData title="BS/JO Number" details={data.bs_number} />
              <ListItemData
                title="Transaction Type"
                details={data.transaction_type}
              />
              <ListItemData
                title="Service Location"
                details={data.service_location}
              />
              <ListItemData
                title="SERVICE TYPE"
                details={renderServiceType(appointmentData?.packages)}
              />
              <ListItemData
                title="MILEAGE"
                details={`${
                  data.mileage ? numberWithCommas(data.mileage) : ""
                }KM`}
              />
              {!isCarBuying() && (
                <ListItemData
                  title="CUSTOMER TYPE"
                  details={data?.customer_type?.name || ""}
                />
              )}
              <ListItemData title="ADS SOURCE" details={data.ads_source} />
              <ListItemData
                title="PROPERTY TYPE"
                details={data.property_type}
              />
              <ListItemData
                title="PARKING SPACE"
                details={data.parking_space}
              />
            </List>
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 0 }}>
            <ListItem
              style={{
                borderBottom: "1px solid #1f2638",
                display: "block",
                padding: "0",
                margin: 0,
              }}
            >
              <ListItemText
                className={classes.customerDetails}
                primary="REMARKS:"
              />
              <ListItemText
                style={{ textAlign: "start !important" }}
                secondary={data.remarks}
              />
            </ListItem>
          </Grid>
          <Grid container>
            <Grid item xs={12} spacing={2} style={{ margin: "0 0.8rem" }}>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableHead}>
                    <TableRow>
                      <TableCell className={classes.tableTH}>
                        PARTICULARS
                      </TableCell>
                      <TableCell className={classes.tableTH}>Remarks</TableCell>
                      <TableCell className={classes.tableTH} align="right">
                        PRICE
                      </TableCell>
                      <TableCell className={classes.tableTH} align="right">
                        QTY
                      </TableCell>
                      <TableCell className={classes.tableTH} align="right">
                        TOTAL
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {hasPreDispatchPackage && (
                      <>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            <Typography
                              variant="h5"
                              className={classes.tableListHead}
                            >
                              PACKAGES
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                        <TableRow>
                          {appointmentData?.packages
                            ?.filter((p) => !p.appointment_post_dispatch_id)
                            ?.map((pckg) => (
                              <React.Fragment key={pckg.id}>
                                <TableCell className={classes.tableCell}>
                                  <p
                                    style={{
                                      margin: "0 0 0 1rem",
                                      textAlign: "start",
                                    }}
                                  >
                                    {pckg?.package?.name || pckg?.service_name}
                                  </p>
                                  {pckg?.package?.inclusions?.map(
                                    (inclusion) => (
                                      <p
                                        style={{
                                          margin: "0 0 0 1rem",
                                          fontWeight: "normal",
                                          textAlign: "start",
                                        }}
                                        key={inclusion.id}
                                      >
                                        {inclusion?.service?.name}{" "}
                                        {inclusion.description &&
                                          `(${inclusion.description})`}
                                      </p>
                                    )
                                  )}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {pckg.remarks}
                                </TableCell>
                                <TableCell
                                  className={classes.tableCell}
                                  style={{ position: "relative" }}
                                >
                                  <p
                                    style={{
                                      position: "absolute",
                                      margin: "auto",
                                      left: 0,
                                      right: 0,
                                      top: "4px",
                                    }}
                                  >
                                    &#8369;{numberConverter(pckg?.price)}
                                  </p>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {pckg?.quantity}
                                  {pckg?.package?.inclusions?.map(
                                    (inclusion) => (
                                      <p
                                        style={{
                                          margin: "0",
                                          fontWeight: "normal",
                                          // textAlign: "start",
                                        }}
                                        key={inclusion.id}
                                      >
                                        {inclusion?.service_name ===
                                        "Engine Oil Change"
                                          ? appointmentData.liter_of_oil
                                          : inclusion?.service?.name ===
                                            "Engine Oil Change"
                                          ? appointmentData.liter_of_oil
                                          : inclusion.quantity}
                                      </p>
                                    )
                                  )}
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.total}`}
                                  style={{ position: "relative" }}
                                >
                                  <p
                                    style={{
                                      position: "absolute",
                                      margin: "auto",
                                      left: 0,
                                      right: "10px",
                                      top: "4px",
                                    }}
                                  >
                                    &#8369;{numberConverter(pckg.total)}
                                  </p>
                                </TableCell>
                              </React.Fragment>
                            ))}
                        </TableRow>
                      </>
                    )}
                    {hasPreDispatchService || hasPreDispatchPackageAddOns ? (
                      <>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            <Typography
                              variant="h5"
                              className={classes.tableListHead}
                            >
                              SERVICES
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                        {appointmentData?.services
                          ?.filter((p) => !p.appointment_post_dispatch_id)
                          ?.map((service) => (
                            <TableRow>
                              <React.Fragment key={service.id}>
                                <TableCell className={classes.tableCell}>
                                  <p
                                    style={{
                                      margin: "0 0 0 1rem",
                                      textAlign: "start",
                                    }}
                                  >
                                    {service.service_name}
                                  </p>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {service?.remarks}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  &#8369;{numberConverter(service?.price)}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {service?.quantity}
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.total}`}
                                >
                                  &#8369;{numberConverter(service?.total)}
                                </TableCell>
                              </React.Fragment>
                            </TableRow>
                          ))}
                        {appointmentData?.appointment_package_add_ons?.map(
                          (service) => (
                            <TableRow>
                              <React.Fragment key={service.id}>
                                <TableCell className={classes.tableCell}>
                                  <p
                                    style={{
                                      margin: "0 0 0 1rem",
                                      textAlign: "start",
                                    }}
                                  >
                                    {service?.service_name}
                                  </p>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {service?.remarks || ""}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  &#8369;0
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  1
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.total}`}
                                >
                                  &#8369;0
                                </TableCell>
                              </React.Fragment>
                            </TableRow>
                          )
                        )}
                      </>
                    ) : null}
                    {hasPreDispatchParts && (
                      <>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            <Typography
                              variant="h5"
                              className={classes.tableListHead}
                            >
                              PARTS
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                        {appointmentData?.service_parts
                          ?.filter((p) => !p.appointment_post_dispatch_id)
                          ?.map((part) => (
                            <TableRow>
                              {part.is_shown_in_jo && (
                                <React.Fragment key={part.id}>
                                  <TableCell className={classes.tableCell}>
                                    <p
                                      style={{
                                        margin: "0 0 0 1rem",
                                        textAlign: "start",
                                      }}
                                    >
                                      {part?.name}
                                    </p>
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {part.remarks}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    &#8369;{numberConverter(part.price)}
                                  </TableCell>
                                  <TableCell className={classes.tableCell}>
                                    {part.quantity}
                                  </TableCell>
                                  <TableCell
                                    className={`${classes.tableCell} ${classes.total}`}
                                  >
                                    &#8369;{numberConverter(part.totalPrice)}
                                  </TableCell>
                                </React.Fragment>
                              )}
                            </TableRow>
                          ))}
                      </>
                    )}
                    {hasPreDispatchTires && (
                      <>
                        <TableRow>
                          <TableCell className={classes.tableCell}>
                            <Typography
                              variant="h5"
                              className={classes.tableListHead}
                            >
                              TIRES
                            </Typography>
                          </TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                          <TableCell className={classes.tableCell}></TableCell>
                        </TableRow>
                        {appointmentData?.service_tires
                          ?.filter((p) => !p.appointment_post_dispatch_id)
                          ?.map((tire) => (
                            <TableRow>
                              <React.Fragment key={tire.id}>
                                <TableCell className={classes.tableCell}>
                                  <p
                                    style={{
                                      margin: "0 0 0 1rem",
                                      textAlign: "start",
                                    }}
                                  >
                                    {tire.sku}
                                  </p>
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {tire.remarks}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  &#8369;{numberConverter(tire.price)}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {tire.quantity}
                                </TableCell>
                                <TableCell
                                  className={`${classes.tableCell} ${classes.total}`}
                                >
                                  &#8369;{numberConverter(tire.total)}
                                </TableCell>
                              </React.Fragment>
                            </TableRow>
                          ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className={classes.detailsContainer}
            spacing={1}
          >
            <Grid item xs={4}>
              <Box className="box_print_info1">
                <List style={{ paddingBottom: 0 }}>
                  <ListItem className={classes.paymentDetailsTitle}>
                    PAYMENT DETAILS
                  </ListItem>
                  <ListItem className={classes.paymentDetails}>
                    <ListItemText primary="PAYMENT METHOD" />
                    <ListItemText
                      secondary={PaymentMethod[data.payment_method]}
                    />
                  </ListItem>
                  <ListItem className={classes.paymentDetails}>
                    <ListItemText primary="PAYMENT STATUS" />
                    <ListItemText
                      secondary={renderPaymentStatus(data?.payment_status)}
                    />
                  </ListItem>
                  {paymentReference(data?.payment, "")}
                  <ListItem
                    className={classes.computationTitle}
                    style={{ margin: "1rem 0" }}
                  >
                    <ListItemText primary="SERVICE ADVISOR" />
                    <ListItemText
                      secondary={`${data?.created_by?.name || ""} ${
                        data?.created_by?.lastname || ""
                      }`}
                    />
                  </ListItem>
                  <Card variant="outlined" className={classes.cards}>
                    <CardContent className={classes.technician}>
                      TECHNICIAN
                    </CardContent>
                    <CardContent
                      style={{
                        fontWeight: 700,
                        paddingBottom: "2px",
                        fontSize: "8px",
                        textAlign: "center",
                      }}
                    >
                      SIGNATURE OVER PRINTED NAME | DATE
                    </CardContent>
                  </Card>
                </List>
              </Box>
            </Grid>
            <Grid
              item
              xs={4}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Card variant="outlined" className={classes.cards}>
                <CardContent className={classes.technician}>
                  CUSTOMER
                </CardContent>
                <CardContent
                  style={{
                    fontWeight: 700,
                    paddingBottom: "2px",
                    textAlign: "center",
                    fontSize: "8px",
                  }}
                >
                  SIGNATURE OVER PRINTED NAME | DATE
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={4} className="print_grid_Info2">
              <Box className="box_print_info2">
                <List>
                  <ListItem className={classes.computation}>
                    <ListItemText primary="SUBTOTAL" />
                    <ListItemText
                      secondary={`₱${numberConverter(
                        data?.sub_total - data?.sub_jo_total
                      )}`}
                    />
                  </ListItem>
                  <ListItem className={classes.computation}>
                    <ListItemText primary="CONVENIENCE FEE" />
                    <ListItemText
                      secondary={`₱${numberConverter(data?.service_fee)}`}
                    />
                  </ListItem>
                  {additionalFees(data?.appointment_fees)}
                  <ListItem className={classes.computation}>
                    <ListItemText primary="DISCOUNT" style={{ color: "red" }} />
                    <ListItemText
                      primary={`₱${numberConverter(data?.discount)}`}
                      style={{
                        color: "red",
                        textAlign: "right",
                        paddingRight: "4px",
                      }}
                    />
                  </ListItem>
                  <ListItem className={classes.computationTitle}>
                    <ListItemText primary="GRAND TOTAL" />
                    <ListItemText
                      secondary={`₱${numberConverter(
                        parseFloat(data?.sub_total) -
                          parseFloat(data?.sub_jo_total || 0) +
                          parseFloat(data?.service_fee) -
                          parseFloat(data?.discount) +
                          totalAdditionalFees
                      )}`}
                    />
                  </ListItem>
                  {paymentType(data?.payment)}
                  <ListItem className={classes.computationTitle}>
                    <ListItemText primary="BALANCE" />
                    <ListItemText
                      style={{ color: "red !important" }}
                      secondary={`₱${numberConverter(
                        parseFloat(data?.sub_total) -
                          parseFloat(data?.sub_jo_total || 0) +
                          parseFloat(data?.service_fee) -
                          parseFloat(data?.discount) -
                          parseFloat(totalReservationMade) +
                          totalAdditionalFees
                      )}`}
                    />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Box>
              <Typography paragraph className={classes.notes}>
                NOTES:
              </Typography>
              <Typography
                paragraph
                style={{ fontWeight: "600", fontSize: "8px", margin: 0 }}
              >
                Under MAP Uniform Inspection Guidelines, we are required to
                document all our findings on your vehicle. Our assigned mechanic
                should bring you to your car and show you the needed repairs.
                This document serves as your Billing Statement. We provide
                digital official receipt; hard copy upon request. Please note
                that Warranty is void for parts/oil used not purchased from
                Mechanigo.ph.{""}
              </Typography>
            </Box>
          </Grid>
          {isCarBuying() && (
            <Grid item xs={12}>
              <Box display={"flex"} justifyContent={"center"}>
                <img
                  src="https://mechanigoph.sgp1.digitaloceanspaces.com/logo/lemon%20aid%20logo.png"
                  className={classes.lemonBusterLogo}
                  alt="Logo"
                />
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"center"}>
              <div>
                -----------------------------CUSTOMER'S
                COPY-----------------------------
              </div>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
});
export default CustomerCopy;
