import React, { Fragment, useState, useCallback } from "react";
import MaterialTable from "material-table";
import moment from "moment";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import { green, yellow } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import useModal from "../../hooks/use-modal";
import AlertDialog from "../booking/AlertDialog";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import TransformIcon from "@material-ui/icons/Transform";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

// components
import AddToBookingForm from "./components/AddToBookingForm";
import ViewQuotation from "./components/ViewQuotation";

// Api config
import config from "../../utils/config";
import UpdateQuotation from "./components/UpdateQuotation";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center !important",
  },
  view: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    color: yellow[600],
    // maxHeight: "2px !important",
    "&:hover": {
      backgroundColor: "rgba(255, 253, 231,1.0)",
    },
  },
  delete: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  add: {
    color: green[600],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const QuotationTable = React.memo((props) => {
  const classes = useStyles();

  const { tableRef, token } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const [addQuotation, setAddQuotation] = useState({
    open: false,
    isLoading: false,
  });

  const [loading, setLoading] = useState(false);

  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  const {
    modalState: modalStateCreate,
    modalData: modalDataCreate,
    openHandler: modalOpenCreate,
    closeHandler: modalCloseCreate,
  } = useModal();

  // =================Quotation Changed Status - (Function)=================
  const handleChange = useCallback(
    (data, event) => {
      let status = event.target.value;
      data.status = status;
      let dataVal = data;

      axios
        .put(`${config.api}/quotations/${data.id}`, dataVal, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setAlert(true);
          setMessage("Successfully Updated");
          setTypeAlert("success");
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setAlert(true);
          setTypeAlert("error");
          setMessage("Something went wrong please try again.");
          tableRef.current && tableRef.current.onQueryChange();
        });
    },
    [tableRef, token]
  );

  const handleSubmitQuotation = (data) => {
    let date = moment(data.ap_date).format("YYYY-MM-DD");
    let time = moment(data.ap_time).format("HH:mm:ss");
    let appointmentDateval = moment(`${date} ${time}`).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    let combinedServices = data.services; // this is the packages

    let manualServices = data.manualServices; // this is the services

    if (manualServices.length > 0) {
      let transformedManualServices = manualServices.map((item) => {
        return {
          id: item.id,
          name: item.name,
          remarks: item.remarks,
          quantity: item.quantity,
          price: item.price,
          total: item.totalPrice,
          type: "manual",
        };
      });
      combinedServices = [...combinedServices, ...transformedManualServices];
    }

    delete data.manualServices;
    delete data.manualServicesList;
    delete data.models;
    delete data.servicesList;

    const dataToSave = {
      ...data,
      discount: data.discounts ? numberConverter(data.discounts) : 0.0,
      model: `${data.make} ${data.model}`,
      discount: data.discounts ? parseFloat(data.discounts) : 0.0,
      services: combinedServices,
      sub_total: data.subTotal,
      distance_in_km: data.distance_in_km || 0,
      paymentMethod: data.payment_method,
      quotation_id: data.id,
      appointmentDate: appointmentDateval,
    };

    setAddQuotation({ ...addQuotation, open: false, isLoading: true });
    axios
      .post(`${config.api}/appointment`, dataToSave, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        tableRef.current && tableRef.current.onQueryChange();
        setAlert(true);
        setTypeAlert("success");
        setMessage("Successfully added.");
      })
      .catch((error) => {
        setAlert(true);
        setTypeAlert("error");
        setMessage("Something went wrong please try again.");
      })
      .finally(() => {
        modalCloseCreate();
        setAddQuotation({ ...addQuotation, isLoading: false });
      });
  };

  const handleUpdate = useCallback(
    (data) => {
      setLoading(true);
      let notManual = data.services;

      let transformedNotManualServices = notManual.map((item) => {
        const itemId = data?.servicesList?.find((i) => i.name === item.name);
        return {
          id: itemId?.id || null,
          name: item.name,
          quantity: item.quantity,
          price: item.price,
          total: item.price,
          type: "",
        };
      });

      let combinedServices = transformedNotManualServices; // this is the packages

      let manualServices = data.manualServices; // this is the services

      if (manualServices.length > 0) {
        let transformedManualServices = manualServices.map((item) => {
          return {
            id: item.id,
            name: item.name,
            remarks: item.remarks,
            quantity: item.quantity,
            price: item.price,
            total: item.totalPrice,
            type: "manual",
          };
        });
        combinedServices = [...combinedServices, ...transformedManualServices];
      }

      let update1 = {
        services: combinedServices,
        parts: data.parts || [],
        service_fee: numberConverter(data.service_fee),
        tires: data.tires || [],
        discount: data.discounts ? numberConverter(data.discounts) : 0.0,
        subTotal: data.subTotal,
        total: data.total,
      };

      let update2 = {
        name: data.name,
        lastname: data.lastname,
        email: data.email,
        contact_number: data.contact_number,
        make: data.make,
        model: data.model,
        year: data.year,
        mileage: data.mileage,
        transmission: data.transmission,
        fuel_type: data.fuel_type,
        liter_of_oil: data.liter_of_oil,
        engine_size: data.engine_size,
        parking_space: data.parking_space,
        plate_number: data.plate_number,
        remarks: data.remarks,
        distance_in_km: numberConverter(data.distance_in_km),
        address: data.address,
        landmarks: data.landmarks,
        province: data.province,
        municipality: data.municipality,
        barangay: data.barangay,
        zip_code: data.zip_code,
      };

      axios
        .put(`${config.api}/quotations/${data.id}`, update1, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => {
          // console.log(error);
        });

      axios
        .patch(`${config.api}/quotations/${data.id}`, update2, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          setAlert(true);
          setMessage("Successfully Updated");
          setTypeAlert("success");
          modalCloseUpdate();
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setLoading(false);
          setAlert(true);
          setMessage("Something went wrong please try again.");
          setTypeAlert("error");
          modalCloseUpdate();
        });
    },
    [alert, tableRef, modalCloseUpdate, token]
  );

  const downloadAsPDF = async (data) => {
    try {
      const response = await axios.get(
        `${config.api}/download/quotation/${data.id}`,
        {
          responseType: "blob", // Important to get the response as a Blob
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Create a Blob from the response data
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a link element
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute with a dynamic filename
      const timestamp = new Date()
        .toISOString()
        .replace(/[:\-T.]/g, "")
        .slice(0, 14);
      link.setAttribute("download", `quotation-${timestamp}.pdf`);

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger the download by simulating a click
      link.click();

      // Clean up by removing the link element
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the PDF", error);
    }
  };

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      {/* =================View - (MODAL)================= */}
      <ViewQuotation
        open={modalStateView}
        data={modalDataView}
        handleViewClose={modalCloseView}
      />

      {/* =================Update Remarks - (MODAL)================= */}
      <UpdateQuotation
        open={modalStateUpdate}
        data={modalDataUpdate}
        handleUpdateClose={modalCloseUpdate}
        isLoading={loading}
        handleUpdate={handleUpdate}
      />

      {/* =================Create Quotation - (MODAL)================= */}
      <AddToBookingForm
        open={modalStateCreate}
        data={modalDataCreate}
        onClose={modalCloseCreate}
        handleSubmitQuotation={handleSubmitQuotation}
        isLoading={addQuotation.isLoading}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Quotations/Estimates`}
        tableRef={tableRef}
        columns={[
          {
            title: "Quotation Date",
            render: (client) => {
              return moment(client.created_at).format("YYYY-MM-DD");
            },
          },
          {
            title: "Name",
            render: (client) => {
              return (
                <>
                  {client.name} {client.lastname}
                </>
              );
            },
          },
          { title: "Email", field: "email" },
          { title: "Contact#", field: "phone_number" },
          { title: "Address", field: "address" },
          {
            title: "Make/Model",
            render: (client) => {
              return (
                <span style={{ textTransform: "capitalize" }}>
                  {client.make} {client.model}
                </span>
              );
            },
          },
          { title: "Fuel Type", field: "fuel_type" },
          // { title: "Services", field: "services" },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/quotations?";
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "view",
            tooltip: "View",
            onClick: (event, rowData) => {
              modalOpenView(rowData);
            },
          },
          {
            icon: "update",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          {
            icon: "add",
            tooltip: "Create Quotation",
            onClick: (event, rowData) => {
              modalOpenCreate(rowData);
            },
          },
          {
            icon: "download",
            tooltip: "Download to pdf",
            onClick: (event, rowData) => {
              downloadAsPDF(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================View=====================
            if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    id="view"
                    aria-label="view"
                    size="small"
                    color="default"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            if (props.action.icon === "update") {
              if (!props.data.is_appointment) {
                return (
                  <Tooltip title="Update">
                    <IconButton
                      aria-label="update"
                      className={classes.update}
                      size="small"
                      onClick={(event) =>
                        props.action.onClick(event, props.data)
                      }
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                );
              } else return null;
            }
            if (props.action.icon === "add") {
              if (!props.data.is_appointment) {
                return (
                  <Tooltip title="Convert to booking">
                    <IconButton
                      aria-label="convert to booking"
                      className={classes.add}
                      size="small"
                      onClick={(event) =>
                        props.action.onClick(event, props.data)
                      }
                    >
                      <TransformIcon />
                    </IconButton>
                  </Tooltip>
                );
              } else return null;
            }
            if (props.action.icon === "download") {
              return (
                <Tooltip title="Download as PDF">
                  <IconButton
                    id="download"
                    aria-label="view"
                    size="small"
                    color="default"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 20,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          sorting: false,
        }}
      />
    </Fragment>
  );
});

export default QuotationTable;
