import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const CustomDaysComponent = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <TextField
      fullWidth
      select
      error={errors.service_location && touched.service_location}
      helperText={
        errors.service_location && touched.service_location
          ? errors.service_location
          : ""
      }
      {...field}
      {...props}
    >
      {[
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ].map((option) => (
        <MenuItemStyle key={option} value={option}>
          {option}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default CustomDaysComponent;
