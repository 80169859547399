import React, { Fragment } from "react";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import { yellow } from "@material-ui/core/colors";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import VisibilityIcon from "@material-ui/icons/Visibility";

import config from "../../utils/config";

const useStyles = makeStyles((theme) => ({
  update: {
    color: yellow["A200"],
    "&:hover": {
      background: "rgba(255, 253, 231,1.0)",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const CustomerVehicles = React.memo((props) => {
  const { customerId, tableRef } = props;

  const token = localStorage.getItem("token");

  return (
    <Fragment>
      <MaterialTable
        icons={tableIcons}
        title={`Customer Garage`}
        tableRef={tableRef}
        columns={[
          {
            title: "Make & Model",
            field: "model",
          },
          { title: "Year", field: "year" },
          { title: "Plate Number", field: "plate_number" },
          { title: "Engine Size", field: "engine_size" },
          { title: "Fuel Type", field: "fuel_type" },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = `${config.api}/customer/vehicles/${customerId}?`;
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };

            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "4px",
            marginBottom: "-1px",
          },
          pageSize: 20,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
        }}
      />
    </Fragment>
  );
});

export default CustomerVehicles;
