import React, { Fragment } from "react";
import PendingAppointments from "./PendingAppointments";
import ConfirmedAppointments from "./ConfirmedAppointments";
import CompletedAppointments from "./CompletedAppointments";
import { Box, Typography, Tabs, Tab } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    "aria-controls": `scrollable-prevent-tabpanel-${index}`,
  };
}

const CustomerAppointments = React.memo((props) => {
  const { customerId } = props;
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <Fragment>
      <Tabs
        value={activeTab}
        onChange={(e, newValue) => {
          setActiveTab(newValue);
        }}
        aria-label="simple tabs example"
      >
        <Tab label="Pending Service" {...a11yProps(0)} />
        <Tab label="Confirmed Service" {...a11yProps(1)} />
        <Tab label="Completed Service" {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={activeTab} index={0}>
        <PendingAppointments customerId={customerId} />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <ConfirmedAppointments customerId={customerId} />
      </TabPanel>
      <TabPanel value={activeTab} index={2}>
        <CompletedAppointments customerId={customerId} />
      </TabPanel>
    </Fragment>
  );
});

export default CustomerAppointments;
