import React, { useEffect, useMemo } from "react";

const numberConverter = (number) => {
  let value = Number(parseFloat(number)).toLocaleString("en", {
    minimumFractionDigits: 2,
  });
  return value;
};

const strToNumberConverter = (number) => {
  if (Number.isInteger(number)) return number;
  return number.replace(/,/g, "");
};

const CustomTotalComponentUpdate = ({
  field, // { name, value, onChange, onBlur }
  form: { values, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const memoizedValues = useMemo(() => values, [values]);

  useEffect(() => {
    const {
      services,
      manualServices,
      service_fee: initialServiceFee,
      discounts,
      additionalFees = [],
      parts,
      tires,
      payment_amount,
    } = memoizedValues;
    const serviceFee = initialServiceFee
      ? strToNumberConverter(initialServiceFee)
      : 0.0;

    // Calculate additional fees
    const _additionalFees = additionalFees?.reduce(
      (acc, item) => acc + parseFloat(item.amount),
      0
    );

    // Calculate subtotal for packages
    const packagesTotalPrice = services.reduce(
      (acc, item) => {
        const itemPrice = parseFloat(item.price) * item.quantity;

        // Check if item is post-dispatch
        if (item.appointment_post_dispatch_id) {
          if (
            (item.post_dispatch && item.post_dispatch?.status_id === 4) ||
            (item.post_dispatch && item.post_dispatch?.status_id === 6)
          ) {
            // overall total, all sub JOs
            acc.postDispatchTotal += itemPrice; // Add to post-dispatch total
          } else {
            // if boolean that mean this will be the total of new subjo being added
            acc.currentPostDispatchTotal += itemPrice;
          }
        } else {
          acc.preDispatchTotal += itemPrice; // Add to pre-dispatch total
        }

        return acc;
      },
      { preDispatchTotal: 0, postDispatchTotal: 0, currentPostDispatchTotal: 0 } // Initial accumulator values
    );

    // Calculate total price for services
    const servicesTotalPrice = manualServices.reduce(
      (acc, item) => {
        const itemTotalPrice = parseFloat(item.totalPrice);

        // Check if item is post-dispatch
        if (item.appointment_post_dispatch_id) {
          if (
            (item.post_dispatch && item.post_dispatch?.status_id === 4) ||
            (item.post_dispatch && item.post_dispatch?.status_id === 6)
          ) {
            // overall total, all sub JOs
            acc.postDispatchTotal += itemTotalPrice; // Add to post-dispatch total
          } else {
            // if boolean that mean this will be the total of new subjo being added
            acc.currentPostDispatchTotal += itemTotalPrice;
          }
        } else {
          acc.preDispatchTotal += itemTotalPrice; // Add to pre-dispatch total
        }

        return acc;
      },
      { preDispatchTotal: 0, postDispatchTotal: 0, currentPostDispatchTotal: 0 } // Initial accumulator values
    );

    // Calculate total price for parts
    const partsTotalPrice = parts.reduce(
      (acc, item) => {
        const itemTotalPrice = parseFloat(item.totalPrice);

        // Check if item is post-dispatch
        if (item.appointment_post_dispatch_id) {
          if (
            (item.post_dispatch && item.post_dispatch?.status_id === 4) ||
            (item.post_dispatch && item.post_dispatch?.status_id === 6)
          ) {
            // overall total, all sub JOs
            acc.postDispatchTotal += itemTotalPrice; // Add to post-dispatch total
          } else {
            // if boolean that mean this will be the total of new subjo being added
            acc.currentPostDispatchTotal += itemTotalPrice;
          }
        } else {
          acc.preDispatchTotal += itemTotalPrice; // Add to pre-dispatch total
        }

        return acc;
      },
      { preDispatchTotal: 0, postDispatchTotal: 0, currentPostDispatchTotal: 0 } // Initial accumulator values
    );

    // Calculate total price for tires
    const tiresTotalPrice = tires.reduce(
      (acc, item) => {
        const itemTotalPrice = parseFloat(item.totalPrice);

        // Check if item is post-dispatch
        if (item.appointment_post_dispatch_id) {
          if (
            (item.post_dispatch && item.post_dispatch?.status_id === 4) ||
            (item.post_dispatch && item.post_dispatch?.status_id === 6)
          ) {
            // overall total, all sub JOs
            acc.postDispatchTotal += itemTotalPrice; // Add to post-dispatch total
          } else {
            // if boolean that mean this will be the total of new subjo being added
            acc.currentPostDispatchTotal += itemTotalPrice;
          }
        } else {
          acc.preDispatchTotal += itemTotalPrice; // Add to pre-dispatch total
        }

        return acc;
      },
      { preDispatchTotal: 0, postDispatchTotal: 0, currentPostDispatchTotal: 0 } // Initial accumulator values
    );

    // Parse total discount
    const discount = values?.discounts
      ? strToNumberConverter(values.discounts)
      : 0.0;

    const paymentAmount = parseFloat(payment_amount) || 0;

    const subTotal =
      parseFloat(packagesTotalPrice.preDispatchTotal) +
      parseFloat(partsTotalPrice.preDispatchTotal) +
      servicesTotalPrice.preDispatchTotal +
      parseFloat(tiresTotalPrice.preDispatchTotal);
    const total =
      parseFloat(serviceFee) +
      subTotal -
      parseFloat(discount) -
      paymentAmount +
      _additionalFees;

    const currentPostDispatchTotal =
      parseFloat(packagesTotalPrice.currentPostDispatchTotal) +
      servicesTotalPrice.currentPostDispatchTotal +
      parseFloat(partsTotalPrice.currentPostDispatchTotal) +
      parseFloat(tiresTotalPrice.currentPostDispatchTotal);

    const postDispatchSubTotal =
      parseFloat(packagesTotalPrice.postDispatchTotal) +
      servicesTotalPrice.postDispatchTotal +
      parseFloat(partsTotalPrice.postDispatchTotal) +
      parseFloat(tiresTotalPrice.postDispatchTotal) +
      currentPostDispatchTotal;

    const postDispatchTotal =
      parseFloat(packagesTotalPrice.postDispatchTotal) +
      servicesTotalPrice.postDispatchTotal +
      parseFloat(partsTotalPrice.postDispatchTotal) +
      parseFloat(tiresTotalPrice.postDispatchTotal) +
      currentPostDispatchTotal;

    // Ensure the final total is not negative
    const totalFinal = Math.max(0, total);

    setFieldValue("current_post_dispatch_sub_total", currentPostDispatchTotal);
    setFieldValue("current_post_dispatch_total", currentPostDispatchTotal);

    setFieldValue("post_dispatch_overall_sub_total", postDispatchSubTotal);
    setFieldValue("post_dispatch_overall_total", postDispatchTotal);

    setFieldValue("subTotal", subTotal);
    setFieldValue("total", totalFinal);
  }, [
    values.services,
    values.manualServices,
    values.parts,
    values.service_fee,
    values.discounts,
    values.tires,
    values.payment_amount,
  ]);

  return (
    <React.Fragment>
      <input {...props} {...field} style={{ display: "none" }} />
      <span>{field.value ? numberConverter(+field.value) : "0.00"}</span>
    </React.Fragment>
  );
};
export default CustomTotalComponentUpdate;
