import * as React from "react";
import axios from "axios";

import AsyncSelect from "react-select/async";

// Api config
import config from "../../../utils/config";

const CustomerSelect = ({ setFieldValue }) => {
  const token = localStorage.getItem("token");

  const loadOptions = async (inputValue) => {
    try {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${config.api}/users/customers?search=${inputValue}`,
        options
      );

      // Destructuring the response to extract the nested 'data' property
      const {
        data: {
          data: { data: customers },
        },
      } = response;

      return customers;
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchNearestHub = async (province, municipality, setFieldValue) => {
    const { data: nearestHub } = await axios.get(`
      ${config.api}/nearest-hub?province=${province}&municipality=${municipality}
    `);
    if (nearestHub) setFieldValue("service_location", nearestHub);
  };

  const handleChange = async (data) => {
    const {
      customer_id,
      firstname,
      address,
      email,
      phone,
      lastname,
      landmarks,
      province,
      municipality,
      barangay,
    } = data || {};

    const c_name = firstname || "";
    const first_name = firstname || "";
    const last_name = lastname || "";

    setFieldValue(`customer_id`, customer_id);
    setFieldValue(`c_name`, c_name);
    setFieldValue(`first_name`, first_name);
    setFieldValue(`last_name`, last_name);
    setFieldValue(`address`, address || "");
    setFieldValue(`email`, email || "");
    setFieldValue(`phone`, phone || "");
    setFieldValue(`landmarks`, landmarks || "");
    setFieldValue(`province`, province || "");
    setFieldValue(`municipality`, municipality || "");
    setFieldValue(`barangay`, barangay || "");

    if (province && municipality && barangay) {
      const {
        data: { fee_amount },
      } = await axios.get(
        `${config.api}/home-service-fee/${province}/${municipality}/${barangay}`
      );

      // setFieldValue(`service_fee`, fee_amount || "");
      fetchNearestHub(province, municipality, setFieldValue);
    }
    if (address === "") {
      setFieldValue(`distance_in_km`, "");
    }
    setFieldValue("customer_type", "Retention");
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        isClearable
        defaultOptions
        placeholder="Select Customer"
        hideSelectedOptions
        onChange={(value) => handleChange(value)}
        loadOptions={loadOptions}
        getOptionLabel={(option) => option.name}
        formatOptionLabel={(option) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {option.customer_id && (
              <div style={{ fontWeight: "700" }}>{option.customer_id}</div>
            )}
            <small>{`${option.firstname}${
              option.lastname ? ` ${option.lastname}` : ""
            } ${option.email ? `| ${option.email}` : ""}`}</small>
          </div>
        )}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 9999, // Ensures dropdown appears above everything
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999, // Ensures dropdown is above modal/dialog if needed
          }),
        }}
        menuPortalTarget={document.body}
      />
    </>
  );
};

export default CustomerSelect;
