import { Fragment, useRef, useState, useEffect } from "react";
import axios from "axios";
// mui
import { Box } from "@material-ui/core";
// component
import Slots from "../components/bookingSlots/Slots";
// Api config
import config from "../utils/config";

const BookingSlots = (props) => {
  const { token } = props;
  return (
    <Fragment>
      <Box mb={1}>
        <Slots token={token} />
      </Box>
    </Fragment>
  );
};

export default BookingSlots;
