import React, { Fragment, useCallback, useState } from "react";
import axios from "axios";
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
import { tableIcons } from "../booking/TableIcons";
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import { green, yellow } from "@material-ui/core/colors";
import useModal from "../../hooks/use-modal";
import MechanicDelete from "./MechanicDelete";
import MechanicUpdate from "./MechanicUpdate";
import MechanicLeaveTable from "./mechanicLeaveTable/MechanicLeaveTable";
import MechanicAccount from "./MechanicAccount";
import AlertDialog from "../booking/AlertDialog";
import DateRangeIcon from "@material-ui/icons/DateRange";
import LockOpenIcon from "@material-ui/icons/LockOpen";

// Api config
import config from "../../utils/config";

const useStyles = makeStyles((theme) => ({
  view: {
    backgroundColor: yellow["A200"],
    color: "#000",
    "&:hover": {
      color: "#fff",
    },
  },
  update: {
    color: yellow["A200"],
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  delete: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  add: {
    color: green[500],
    "&:hover": {
      backgroundColor: "rgba(232, 245, 233,1.0)",
    },
  },
  leave: {
    maxHeight: "2px !important",
    "&:hover": {
      background: "none !important",
    },
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
}));

const MechanicTable = React.memo((props) => {
  const classes = useStyles();
  const { tableRef, token } = props;

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  const {
    modalState: modalStateLeaveHistory,
    modalData: modalDataLeaveHistory,
    openHandler: modalOpenLeaveHistory,
    closeHandler: modalCloseLeaveHistory,
  } = useModal();

  const {
    modalState: modalStateAccount,
    modalData: modalDataAccount,
    openHandler: modalOpenAccount,
    closeHandler: modalCloseAccount,
  } = useModal();

  const handleUpdateMechanic = useCallback(
    async (data, id) => {
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        await axios.put(`${config.api}/mechanics/${id}`, data, options);

        tableRef.current && tableRef.current.onQueryChange();
        modalCloseUpdate();
        setAlert(true);
        setMessage("Successfully Updated");
        setTypeAlert("success");
      } catch (err) {
        // Handle Error Here
        console.error(err);
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseUpdate();
        setAlert(true);
        setTypeAlert("error");
        setMessage("Something went wrong please try again.");
      }
    },
    [modalCloseUpdate, token, tableRef]
  );

  const handleUploadMechanicPhoto = useCallback(async (data, id) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios.post(`${config.api}/mechanics/upload/${id}`, data, options);
    } catch (error) {
      console.log("error: ", error);
    }
  });

  const handleDeleteMechanic = useCallback(
    async (id) => {
      console.log(id);

      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const resp = await axios.delete(
          `${config.api}/mechanics/${id}`,
          options
        );
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setMessage("Successfully Deleted");
        setTypeAlert("success");
      } catch (err) {
        // Handle Error Here
        console.error(err);
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseDelete();
        setAlert(true);
        setTypeAlert("error");
        setMessage("Something went wrong please try again.");
      }
    },
    [modalCloseDelete, token, tableRef]
  );

  const handleSubmitAccount = useCallback(
    async (withEmail, data) => {
      let dataTransformed = {
        email: data?.email,
        password: data?.password,
      };
      const options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        if (withEmail) {
          await axios.patch(
            `${config.api}/mechanics/${data?.id}/password`,
            { password: data?.password },
            options
          );
        } else {
          await axios.patch(
            `${config.api}/mechanics/${data?.id}/email`,
            dataTransformed,
            options
          );
        }
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseAccount();
        setAlert(true);
        setMessage("Successfully updated");
        setTypeAlert("success");
      } catch (err) {
        // Handle Error Here
        tableRef.current && tableRef.current.onQueryChange();
        modalCloseAccount();
        setAlert(true);
        setTypeAlert("error");
        setMessage("Something went wrong please try again.");
      }
    },
    [modalCloseAccount, token, tableRef]
  );

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />

      {/* =================DELETE - (MODAL)================= */}
      <MechanicDelete
        open={modalStateDelete}
        data={modalDataDelete}
        onClose={modalCloseDelete}
        onDelete={handleDeleteMechanic}
      />

      {/* =================UPDATE - (MODAL)================= */}
      <MechanicUpdate
        open={modalStateUpdate}
        data={modalDataUpdate}
        onClose={modalCloseUpdate}
        onUpdate={handleUpdateMechanic}
        onUpload={handleUploadMechanicPhoto}
      />

      {/* =================Mechanic Leave History Table - (MODAL)================= */}
      {/* <MechanicLeaveTable
        open={modalStateLeaveHistory}
        data={modalDataLeaveHistory}
        onClose={modalCloseLeaveHistory}
        token={token}
      /> */}

      {/* =================Mechanic Account Table - (MODAL)================= */}
      <MechanicAccount
        open={modalStateAccount}
        data={modalDataAccount}
        onClose={modalCloseAccount}
        handleSubmitAccount={handleSubmitAccount}
      />

      <MaterialTable
        icons={tableIcons}
        title={`Mechanics Table`}
        tableRef={tableRef}
        columns={[
          {
            title: "ID",
            field: "id",
          },
          {
            title: "Name",
            render: (data) => {
              return `${data.firstname} ${data.lastname}`;
            },
          },
          {
            title: "Contact #",
            field: "mobile",
          },
          {
            title: "Email",
            field: "email",
          },
          // {
          //   title: "Role",
          //   field: "role",
          // },
          {
            title: "Hub",
            field: "garage.name",
          },
          {
            title: "Service Expertise",
            field: "service_expertise",
          },
          {
            title: "Status",
            render: (client) => {
              let status = client.is_active ? "Active" : "Inactive";
              return (
                <Chip
                  classes={{ sizeSmall: classes.sizeSmall }}
                  size="small"
                  label={status}
                  color={`${client.is_active ? "primary" : "secondary"}`}
                />
              );
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/mechanics?";
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result.data.data,
                  page: result.data.current_page - 1 || 0,
                  totalCount: result.data.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "update",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
          // {
          //   icon: "leave",
          //   tooltip: "Leave History",
          //   onClick: (event, rowData) => {
          //     modalOpenLeaveHistory(rowData);
          //   },
          // },
          {
            icon: "account",
            tooltip: "Change Password",
            onClick: (event, rowData) => {
              modalOpenAccount(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
            // =====================Update=====================
            if (props.action.icon === "update") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    size="small"
                    className={classes.delete}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === "leave") {
              return (
                <Tooltip title="Leave History">
                  <IconButton
                    aria-label="Leave History"
                    size="small"
                    className={classes.leave}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <DateRangeIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Account access=====================
            if (props.action.icon === "account") {
              return (
                <Tooltip title="Account">
                  <IconButton
                    aria-label="Account"
                    size="small"
                    className={classes.leave}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <LockOpenIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
        }}
      />
    </Fragment>
  );
});

export default MechanicTable;
