import React, { Fragment, useState } from "react";
// mui
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Tabs, Tab, Box } from "@material-ui/core";
// Component
import TabPanel from "../../components/attendance/TabPanel";
import SlotsTable from "./SlotsTable";
import OffDaysTable from "./OffDaysTable";

const useStyles = makeStyles((theme) => ({
  appBar: {
    marginBottom: "20px",
  },
}));

const Slots = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Fragment>
      <Box>
        <AppBar position="static" className={classes.appBar}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Schedule Slots" />
            <Tab label="Off Dates" />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <SlotsTable {...props} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <OffDaysTable {...props} />
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default Slots;
