import { Fragment, useState, useEffect } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Divider,
  Box,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { CheckCircle, Add, RemoveCircle } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import { green, red } from "@material-ui/core/colors";
import { Formik, Form, FastField, FieldArray } from "formik";
import * as Yup from "yup";
// Components
import CustomGaragesComponent from "./CustomGaragesComponent";
import CustomDaysComponent from "./CustomDaysComponent";
import CustomTimesComponent from "./CustomTimesComponent";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  button: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
  paper: { maxWidth: "100%" },
  card: { marginBottom: "20px" },
  addnewbutton: {
    backgroundColor: green[500],
    color: "#fff",
    "&:hover": {
      backgroundColor: green[600],
    },
  },
}));

const defaultValues = {
  garage_id: "",
  platform_type: "",
  slots: [
    {
      day: "",
      time_from: "",
      time_to: "",
      slots_cap: "",
      pms: "",
      car_buying: "",
      initial_diagnosis: "",
    },
  ],
};

const AddAppointmentScheduleSlots = React.memo((props) => {
  const classes = useStyles();
  const { data, handleClose, open, handleSubmitSlots } = props;

  const [initialValues, setInitialValues] = useState(defaultValues);

  useEffect(() => {
    if (data && data?.id) {
      data.platform_type_1 =
        data?.platform_type === "website" ||
        data?.platform_type === "website,backoffice";
      data.platform_type_2 =
        data?.platform_type === "backoffice" ||
        data?.platform_type === "website,backoffice";
      setInitialValues(data);
    } else {
      setInitialValues(defaultValues);
    }
  }, [data]);

  const handleOnSubmit = (values) => {
    handleSubmitSlots(values);
  };

  // Yup Validation Schema
  const validationSchema = Yup.object({
    garage_id: Yup.number().required("Garage is required"),
    slots: Yup.array()
      .of(
        Yup.object({
          day: Yup.string().required("Day is required"),
          time_from: Yup.string().required("Time from is required"),
          time_to: Yup.string()
            .required("Time to is required")
            .test(
              "is-greater-than",
              "Time From must be before Time To",
              function (value) {
                const { time_from } = this.parent; // Access other field values within the same object (i.e., `time_from`)
                if (!time_from || !value) return true; // Skip the validation if either field is not filled
                return value > time_from;
              }
            )
            .test(
              "not-equal",
              "Time From and Time To must be different",
              function (value) {
                const { time_from } = this.parent;
                if (!time_from || !value) return true;
                return value !== time_from;
              }
            ),
          slots_cap: Yup.number().required("Slots cap is required"),
          pms: Yup.number()
            .required("PMS is required")
            .max(Yup.ref("slots_cap"), "PMS value cannot exceed slots cap"),
          car_buying: Yup.number()
            .required("Car buying is required")
            .max(
              Yup.ref("slots_cap"),
              "Car buying value cannot exceed slots cap"
            ),
          initial_diagnosis: Yup.number()
            .required("Initial diagnostics is required")
            .max(
              Yup.ref("slots_cap"),
              "Initial diagnostics value cannot exceed slots cap"
            ),
        })
      )
      .required("Must have at least one slot")
      .min(1, "Must have at least one slot"),
  });

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ paper: classes.paper }}
          fullScreen
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleOnSubmit}
          >
            {({
              values,
              errors,
              touched,
              isSubmitting,
              handleChange,
              setFieldValue,
              handleSubmit,
            }) => (
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>
                    {"ADD/UPDATE APPOINTMENT SCHEDULE SLOTS"}
                  </span>
                </DialogTitle>
                <input type="hidden" name="id" />
                <DialogContent dividers>
                  <Grid container spacing={3}>
                    <Grid item xs={3}>
                      <FastField
                        label="Garage"
                        component={CustomGaragesComponent}
                        name="garage_id"
                        type="text"
                        variant="outlined"
                        size="small"
                        onChange={(evt) => {
                          setFieldValue("garage_id", evt.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <Grid container spacing={2} alignItems="center">
                        {/* Title aligned with the checkboxes */}
                        <Grid item>
                          <Typography variant="body">Platform Type</Typography>
                        </Grid>
                        {/* Checkbox for Platform 1 */}
                        <Grid item>
                          <FastField
                            name="platform_type_1"
                            value={values?.platform_type === "website"}
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e)}
                                  />
                                }
                                label="Website"
                              />
                            )}
                          />
                        </Grid>

                        {/* Checkbox for Platform 2 */}
                        <Grid item>
                          <FastField
                            name="platform_type_2"
                            value={values?.platform_type === "backoffice"}
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e)}
                                  />
                                }
                                label="Backoffice"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <Typography variant="h5">Slots</Typography>
                  <FieldArray name="slots">
                    {({ insert, remove, push }) => (
                      <>
                        {values.slots.map((slot, index) => (
                          <Card className={classes.card} key={index}>
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item xs={4}>
                                  <FastField
                                    fullWidth
                                    name={`slots[${index}].day`}
                                    component={CustomDaysComponent}
                                    label="Day"
                                    size="small"
                                    variant="outlined"
                                    error={
                                      touched.slots?.[index]?.day &&
                                      !!errors.slots?.[index]?.day
                                    }
                                    helperText={
                                      touched.slots?.[index]?.day &&
                                      errors.slots?.[index]?.day
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `slots[${index}].day`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <Button
                                    onClick={() => remove(index)}
                                    size="small"
                                    style={{ color: "red" }}
                                    fullWidth
                                    startIcon={<RemoveCircle size="0.9rem" />}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>

                              <Grid container spacing={3}>
                                <Grid item xs={2}>
                                  <FastField
                                    fullWidth
                                    name={`slots[${index}].time_from`}
                                    component={CustomTimesComponent}
                                    label="Time From"
                                    size="small"
                                    variant="outlined"
                                    defaultValue={slot.time_from}
                                    error={
                                      touched.slots?.[index]?.time_from &&
                                      !!errors.slots?.[index]?.time_from
                                    }
                                    helperText={
                                      touched.slots?.[index]?.time_from &&
                                      errors.slots?.[index]?.time_from
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `slots[${index}].time_from`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FastField
                                    fullWidth
                                    name={`slots[${index}].time_to`}
                                    component={CustomTimesComponent}
                                    label="Time To"
                                    size="small"
                                    variant="outlined"
                                    defaultValue={slot.time_from}
                                    error={
                                      touched.slots?.[index]?.time_to &&
                                      !!errors.slots?.[index]?.time_to
                                    }
                                    helperText={
                                      touched.slots?.[index]?.time_to &&
                                      errors.slots?.[index]?.time_to
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `slots[${index}].time_to`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FastField
                                    fullWidth
                                    name={`slots[${index}].slots_cap`}
                                    component={TextField}
                                    label="Cap Slots"
                                    size="small"
                                    variant="outlined"
                                    value={slot.slots_cap}
                                    error={
                                      touched.slots?.[index]?.slots_cap &&
                                      !!errors.slots?.[index]?.slots_cap
                                    }
                                    helperText={
                                      touched.slots?.[index]?.slots_cap &&
                                      errors.slots?.[index]?.slots_cap
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `slots[${index}].slots_cap`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FastField
                                    fullWidth
                                    name={`slots[${index}].pms`}
                                    component={TextField}
                                    label="PMS"
                                    size="small"
                                    variant="outlined"
                                    value={slot.pms}
                                    error={
                                      touched.slots?.[index]?.pms &&
                                      !!errors.slots?.[index]?.pms
                                    }
                                    helperText={
                                      touched.slots?.[index]?.pms &&
                                      errors.slots?.[index]?.pms
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `slots[${index}].pms`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FastField
                                    fullWidth
                                    name={`slots[${index}].car_buying`}
                                    component={TextField}
                                    label="Car Buying"
                                    size="small"
                                    variant="outlined"
                                    value={slot.car_buying}
                                    error={
                                      touched.slots?.[index]?.car_buying &&
                                      !!errors.slots?.[index]?.car_buying
                                    }
                                    helperText={
                                      touched.slots?.[index]?.car_buying &&
                                      errors.slots?.[index]?.car_buying
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `slots[${index}].car_buying`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <FastField
                                    fullWidth
                                    name={`slots[${index}].initial_diagnosis`}
                                    component={TextField}
                                    label="Initial Diagnosis"
                                    size="small"
                                    variant="outlined"
                                    value={slot.initial_diagnosis}
                                    error={
                                      touched.slots?.[index]
                                        ?.initial_diagnosis &&
                                      !!errors.slots?.[index]?.initial_diagnosis
                                    }
                                    helperText={
                                      touched.slots?.[index]
                                        ?.initial_diagnosis &&
                                      errors.slots?.[index]?.initial_diagnosis
                                    }
                                    onChange={(e) => {
                                      setFieldValue(
                                        `slots[${index}].initial_diagnosis`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        ))}
                        <Grid container spacing={3}>
                          <Grid item xs={3}>
                            <Button
                              onClick={() =>
                                push({
                                  day: "",
                                  time_from: "",
                                  time_to: "",
                                  slots_cap: "",
                                  pms: "",
                                  car_buying: "",
                                  initial_diagnosis: "",
                                })
                              }
                              size="small"
                              color="primary"
                              variant="contained"
                              fullWidth
                              className={classes.addnewbutton}
                              startIcon={<Add size="0.9rem" />}
                            >
                              Add Slot
                            </Button>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </FieldArray>
                  <Box my={2}>
                    <Divider />
                  </Box>
                  <DialogActions>
                    <Button onClick={handleClose} size="small" color="default">
                      Close
                    </Button>
                    <Button
                      disabled={isSubmitting && !errors}
                      type="submit"
                      color="primary"
                      variant="contained"
                      autoFocus
                      size="small"
                      endIcon={
                        isSubmitting && !errors ? (
                          <CheckCircle size="0.9rem" />
                        ) : undefined
                      }
                    >
                      {isSubmitting && !errors ? "Submitting" : "Save"}
                    </Button>
                  </DialogActions>
                </DialogContent>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});

export default AddAppointmentScheduleSlots;
