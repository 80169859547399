import { object, date, number, array, string } from "yup";

export const validationSchema = object().shape({
  reference_no: string().required("Reference number is required"),
  transaction_type: string().required("Transaction type is required"),
  created_at: date().required("Booking date is required"),
  first_name: string().required("Customer first name is required"),
  last_name: string().required("Customer last name is required"),
  email: string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Email should be valid."
    )
    .nullable(true),
  phone: string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Contact number is not valid."
    )
    .required("Contact number is required."),
  landmarks: string().nullable(),
  customer_type: object({
    id: number().nullable(true),
    name: string().required("Customer type is required."),
  }),
  coverAddress: string().required(
    "Sorry, your location is currently not within our service area."
  ),
  province: string().required("Province is required."),
  municipality: string().required("Municipality/City is required."),
  barangay: string().required("Barangay is required."),
  make: string().required("Model is required."),
  model: string().required("Model is required."),
  year: string().nullable(),
  plate_number: string().nullable(),
  transmission: string().nullable(),
  mileage: string().typeError("Mileage must be valid.").nullable(),
  fuel_type: string().required("Fuel type is required."),
  payment_method: string().required("Mode of Payment is required."),
  liter_of_oil: string().required("Liter of oil is required."),
  engine_size: number().typeError("Engine size must be valid.").nullable(),
  odometer: string().required("Odometer is required."),
  services: array(
    object({
      id: number().nullable(true),
      name: string().required("Please fill up this field."),
      service_category_id: string().nullable(true),
      description: string().nullable(true),
      quantity: number()
        .required("Quantity is required.")
        .min(1, "Please input atleast 1 item."),
      type: string().nullable(true),
      variant: object({
        id: string().nullable(true),
        service_id: string().nullable(true),
        fuel_type: string().nullable(true),
        vehicle_type: string().nullable(true),
        variant: string().nullable(true),
        liter_of_oil: string().nullable(true),
        price: string().nullable(true),
      }).nullable(true),
    })
  ).nullable(),
  manualServices: array(
    object({
      id: number().nullable(true),
      name: string().required("Please fill up this field."),
      remarks: string().nullable(true),
      quantity: number()
        .required("Quantity is required.")
        .min(1, "Please input atleast 1 item."),
      price: number().nullable(true),
      totalPrice: number().required(""),
      type: string().required(),
    })
  ).nullable(),
  ap_date: date().required("Appointment date is required"),
  ap_time: date().required("Appointment time is required"),
  remarks: string().nullable(),
  ads_source: array()
    .of(string())
    .min(1, "Select at least 1 of ads sources")
    .required("Ads source is required"),
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const carBuyingValidationSchema = object().shape({
  reference_no: string().required("Reference number is required"),
  service_location: string().required("Service Location is required"),
  created_at: date().required("Booking date is required"),
  first_name: string().required("Customer first name is required"),
  last_name: string().required("Customer last name is required"),
  email: string()
    .matches(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Email should be valid."
    )
    .required("Email is required"),
  phone: string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      "Contact number is not valid."
    )
    .required("Contact number is required."),
  seller_type: string().required("Type of seller is required."),
  seller_name: string().required("Seller name is required."),
  address: string().required("Address is required."),
  landmarks: string().nullable(),
  province: string().required("Province is required."),
  municipality: string().required("Municipality/City is required."),
  barangay: string().required("Barangay is required."),
  model: string().required("Model is required."),
  year: string().nullable(),
  payment_method: string().required("Mode of Payment is required."),
  services: array(
    object({
      id: number().nullable(true),
      name: string().required("Please fill up this field."),
      service_category_id: string().nullable(true),
      description: string().nullable(true),
      quantity: number()
        .required("Quantity is required.")
        .min(1, "Please input atleast 1 item."),
      type: string().nullable(true),
      variant: object({
        id: string().nullable(true),
        service_id: string().nullable(true),
        fuel_type: string().nullable(true),
        vehicle_type: string().nullable(true),
        variant: string().nullable(true),
        liter_of_oil: string().nullable(true),
        price: string().nullable(true),
      }).nullable(true),
    })
  ).nullable(),
  ap_date: date().required("Appointment date is required"),
  ap_time: date().required("Appointment time is required"),
  ads_source: array()
    .of(string())
    .min(1, "Select at least 1 of ads sources")
    .required("Ads source is required"),
});
