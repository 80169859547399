import { Fragment, useCallback, useState, useRef } from "react";
import AlertDialog from "../components/booking/AlertDialog";
import CustomersTable from "../components/customers/CustomersTable";

const Customers = (props) => {
  const { token } = props;

  const tableRef = useRef();

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  // =================ALERT - (MODAL)=================
  const handleCloseAlert = useCallback(() => {
    setAlert(false);
    setMessage("");
    setTypeAlert("success");
  }, []);

  return (
    <Fragment>
      <AlertDialog
        open={alert}
        typeAlert={typeAlert}
        message={message}
        handleCloseAlert={handleCloseAlert}
      />
      <CustomersTable tableRef={tableRef} token={token} />
    </Fragment>
  );
};

export default Customers;
