import { messaging } from "./firebase-config";
import { getToken } from "firebase/messaging";

// Assuming you are using an async function to send a request to the server
const sendTokenToServer = async (fcmToken, authToken, userId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/users/${userId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Bearer token for authorization
        },
        body: JSON.stringify({
          fcmToken: fcmToken, // Sending FCM token to server
        }),
      }
    );
    if (!response.ok) {
      throw new Error("Failed to send token to the server.");
    }
    await response.json();
    console.log("Token successfully sent to the server:");
  } catch (error) {
    console.error("Error sending token to the server:", error);
  }
};

export const requestNotificationPermission = async () => {
  try {
    const token = localStorage.getItem("token"); // Authorization token from local storage
    const isAdmin = localStorage.getItem("isAdmin");
    const userId = localStorage.getItem("userId");

    if (!token || !isAdmin || !userId) {
      // User is not logged in or missing required info
      return; // Exit if not logged in or required information is missing
    }

    // Request notification permission from the user
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      // Notification permission granted
      // Try to get the FCM token after permission is granted
      const fcmToken = await getToken(messaging, {
        vapidKey:
          "BPSXK7XjqCumg-fV6sQobyTCFcCW5pvRrWTc2vZelR9wDy6nCvndU158ChAsTrwxTVVxWLXf-b8BpfVEO0z0Gu8", // VAPID key from Firebase Console
      });

      if (fcmToken) {
        // Send the token to the server along with the Authorization bearer token
        await sendTokenToServer(fcmToken, token, userId); // Pass the token from localStorage to the server
      } else {
        console.error("No registration token available.");
      }
    } else if (permission === "denied") {
      console.error("Permission denied. The user has blocked notifications.");
      alert(
        "Notifications are blocked. Please enable them in your browser settings."
      );
    } else if (permission === "default") {
      console.log("Notification permission request was dismissed.");
    }
  } catch (error) {
    console.error("Error requesting notification permission:", error);
  }
};
