import React, { Fragment, useState, useCallback, useRef } from "react";
import MaterialTable from "material-table";
import axios from "axios";
// mui
import IconButton from "@material-ui/core/IconButton";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core/styles";
import { green, red, yellow } from "@material-ui/core/colors";
import Tooltip from "@material-ui/core/Tooltip";
// api config
import config from "../../utils/config";
import useModal from "../../hooks/use-modal";
// Components
import AlertDialog from "../alertDialog/AlertDialog";
import Filters from "./Filters";
import DeleteDialog from "./DeleteDialog";
import AddAppointmentScheduleSlots from "./AddAppointmentScheduleSlots";
import ViewScheduleSlotsDialog from "./ViewScheduleSlotsDialog"

const useStyles = makeStyles((theme) => ({
  delete: {
    backgroundColor: red["A200"],
    color: "#FFF",
    "&:hover": {
      color: "#000",
    },
  },
  update: {
    backgroundColor: green["500"],
    color: "#FFF",
    "&:hover": {
      color: "#000",
    },
    marginRight: "10px",
  },
  view: {
    backgroundColor: yellow["500"],
    color: "#FFF",
    "&:hover": {
      color: "#000",
    },
    marginRight: "10px",
  },
  sizeSmall: {
    height: "18px",
    fontSize: "12px",
  },
  root: {
    textAlign: "center !important",
  },
}));

const SlotsTable = (props) => {
  const { token } = props;
  const tableRef = useRef();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  // ==============MODAL STATES==============
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    typeAlert: "success",
  });

  // Alert Dialog
  const handleCloseAlert = useCallback(
    (event, reason) => {
      if (reason === "clickaway") {
        return;
      }

      setAlert({
        ...alert,
        open: false,
        message: "",
      });
    },
    [alert]
  );

  // View Dialog
  const {
    modalState: modalStateView,
    modalData: modalDataView,
    openHandler: modalOpenView,
    closeHandler: modalCloseView,
  } = useModal();

  // Add / Update Dialog
  const {
    modalState: modalStateUpdate,
    modalData: modalDataUpdate,
    openHandler: modalOpenUpdate,
    closeHandler: modalCloseUpdate,
  } = useModal();

  // Delete Dialog
  const {
    modalState: modalStateDelete,
    modalData: modalDataDelete,
    openHandler: modalOpenDelete,
    closeHandler: modalCloseDelete,
  } = useModal();

  const handleSubmitSlots = useCallback(async (data) => {
    try {
      setLoading(true);
      const payload = { ...data };
      // Set the platform_type based on conditions
      const platformTypes = [];
      if (data?.platform_type_1) platformTypes.push("website");
      if (data?.platform_type_2) platformTypes.push("backoffice");

      payload.platform_type = platformTypes.join(",");

      // Clean up unnecessary fields
      delete payload.platform_type_1;
      delete payload.platform_type_2;
      delete payload.garage;
      delete payload.tableData;

      // Determine the request type and endpoint
      const apiUrl = payload?.id
        ? `${config.api}/appointment-slots/${payload.id}`
        : `${config.api}/appointment-slots`;
      const method = payload?.id ? "put" : "post";

      // Make the request
      await axios({
        method,
        url: apiUrl,
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Handle success response
      setLoading(false);
      modalCloseUpdate();
      setAlert({
        ...alert,
        open: true,
        message: "Successfully added",
        typeAlert: "success",
      });

      // Update table after submission
      if (tableRef.current) {
        tableRef.current.onQueryChange();
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Extract validation errors
        const validationErrors = error.response.data.errors;
        let formattedErrors = "";
        for (const key in validationErrors) {
          if (validationErrors[key].length > 0) {
            formattedErrors += validationErrors[key].join(" ") + " ";
          }
        }
        // Optionally display them in the UI, e.g., using a toast or error message component
        setAlert({
          ...alert,
          open: true,
          message: formattedErrors.trim(),
          typeAlert: "error",
        });
      } else {
        console.error("Error occurred:", error); // Other errors (network issues, etc.)
      }
    } finally {
      setLoading(false);
      modalCloseUpdate();
      tableRef.current && tableRef.current.onQueryChange();
    }
  }, []);

  const handleDelete = useCallback(
    (id) => {
      setLoading(true);
      axios
        .delete(`${config.api}/appointment-slots/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Successfully Deleted",
            typeAlert: "success",
          });
          tableRef.current && tableRef.current.onQueryChange();
        })
        .catch((error) => {
          setLoading(false);
          modalCloseDelete();
          setAlert({
            ...alert,
            open: true,
            message: "Something went wrong please try again.",
            typeAlert: "error",
          });
        });
    },
    [alert, modalCloseDelete, tableRef, token]
  );

  const handleCloseSlots = () => {
    modalCloseUpdate();
  };

  return (
    <Fragment>
      {/* Alert Dialog */}
      <AlertDialog
        open={alert.open}
        typeAlert={alert.typeAlert}
        message={alert.message}
        handleCloseAlert={handleCloseAlert}
      />
       {/* =================View Dialog - (MODAL)================= */}
       <ViewScheduleSlotsDialog
        data={modalDataView}
        open={modalStateView}
        handleClose={modalCloseView}
      />
      {/* =================Add or Update Appointment Schedule Slots Dialog - (MODAL)================= */}
      <AddAppointmentScheduleSlots
        data={modalDataUpdate}
        open={modalStateUpdate}
        handleClose={handleCloseSlots}
        handleSubmitSlots={handleSubmitSlots}
      />
      {/* =================Delete Dialog- (MODAL)================= */}
      <DeleteDialog
        data={modalDataDelete}
        isLoading={loading}
        open={modalStateDelete}
        handleDelete={handleDelete}
        handleDeleteClose={modalCloseDelete}
      />
      <Filters handleOpenAdd={() => modalOpenUpdate(null)} />
      <MaterialTable
        title="Schedule Slots"
        tableRef={tableRef}
        columns={[
          {
            title: "Garage",
            render: (row) => {
              return <p>{row?.garage?.name}</p>;
            },
          },
          {
            title: "Platform Type",
            render: (row) => {
              return <p>{row?.platform_type}</p>;
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve, reject) => {
            let url = config.api + "/appointment-slots?";
            url += `page=${query.page + 1}`;
            if (query.pageSize) {
              url += `&per_page=${query.pageSize}`;
            }
            if (query.search) {
              url += `&search=${query.search}`;
            }
            const options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
              },
            };
            fetch(url, options)
              .then((response) => response.json())
              .then((result) => {
                resolve({
                  data: result?.data?.data,
                  page: result?.data?.current_page - 1 || 0,
                  totalCount: result?.data?.total,
                });
              });
          })
        }
        actions={[
          {
            icon: "view",
            tooltip: "View",
            onClick: (event, rowData) => {
              modalOpenView(rowData);
            },
          },
          {
            icon: "edit",
            tooltip: "Update",
            onClick: (event, rowData) => {
              modalOpenUpdate(rowData);
            },
          },
          {
            icon: "delete",
            tooltip: "Delete",
            onClick: (event, rowData) => {
              modalOpenDelete(rowData);
            },
          },
        ]}
        components={{
          Action: (props) => {
             // =====================View=====================
             if (props.action.icon === "view") {
              return (
                <Tooltip title="View">
                  <IconButton
                    id="view"
                    aria-label="view"
                    size="small"
                    className={classes.view}
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Edit=====================
            if (props.action.icon === "edit") {
              return (
                <Tooltip title="Update">
                  <IconButton
                    aria-label="update"
                    className={classes.update}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <EditOutlinedIcon />
                  </IconButton>
                </Tooltip>
              );
            }
            // =====================Delete=====================
            if (props.action.icon === "delete") {
              return (
                <Tooltip title="Delete">
                  <IconButton
                    id="delete"
                    aria-label="delete"
                    className={classes.delete}
                    size="small"
                    onClick={(event) => props.action.onClick(event, props.data)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              );
            }
          },
        }}
        options={{
          headerStyle: {
            fontWeight: "bold",
          },
          rowStyle: {
            fontSize: ".75rem",
            padding: "0px !important",
          },
          actionsCellStyle: {
            justifyContent: "center",
            padding: "24px",
            marginBottom: "-1px",
          },
          pageSize: 30,
          pageSizeOptions: [],
          actionsColumnIndex: -1,
          tableLayout: "auto",
          sorting: false,
          search: false,
        }}
      />
    </Fragment>
  );
};

export default SlotsTable;
