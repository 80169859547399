import React, { useState } from "react";
import {
  createMuiTheme,
  ThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  Grid,
  FormControl,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddIcon from "@material-ui/icons/Add";
import { Field, Formik, Form, FieldArray } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import SelectAppointmentFeeType from "../../common/SelectAppointmentFeeType";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
}));

const formLabelsTheme = createMuiTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: "#db3131",
        "&$error": {
          color: "#db3131",
        },
      },
    },
  },
});

const AppointmentFee = (props) => {
  const classes = useStyles();

  const {
    data,
    open,
    modalCloseUpdateAppointmentFee,
    handleAddAppointmentFee,
    isLoading,
  } = props;

  const initialValues = {
    fees: data?.appointment_fees?.map((fee) => ({
      feeType: fee.fee_type_id || "",
      feeAmount: fee.amount || "",
    })) || [{ feeType: "", feeAmount: "" }],
  };

  const validationSchema = Yup.object().shape({
    fees: Yup.array().of(
      Yup.object().shape({
        feeType: Yup.string().required("Fee Type is required"),
        feeAmount: Yup.string().required("Fee Amount is required"),
      })
    ),
  });

  const handleSubmit = (values) => {
    handleAddAppointmentFee(values, data.id);
  };

  return (
    <Dialog
      open={open}
      onClose={modalCloseUpdateAppointmentFee}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <ThemeProvider theme={formLabelsTheme}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values }) => (
            <Form>
              <DialogTitle id="alert-dialog-title" className={classes.title}>
                <div
                  className={classes.titleText}
                  style={{ textTransform: "uppercase" }}
                >
                  Booking NO : {data?.reference_no}
                </div>
              </DialogTitle>
              <DialogContent dividers>
                <FieldArray name="fees">
                  {({ push, remove }) => (
                    <>
                      {values.fees.map((_, index) => (
                        <Grid
                          container
                          spacing={2}
                          key={index}
                          alignItems="center"
                        >
                          <Grid item xs={5}>
                            <FormControl fullWidth>
                              <Field
                                name={`fees[${index}].feeType`}
                                label="Fee Type"
                                component={SelectAppointmentFeeType}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={5}>
                            <FormControl fullWidth>
                              <Field
                                name={`fees[${index}].feeAmount`}
                                label="Fee Amount"
                                component={TextField}
                                variant="outlined"
                                size="small"
                                fullWidth
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={2} gap={3}>
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                              onClick={() => remove(index)}
                            >
                              Remove
                            </Button>
                          </Grid>
                        </Grid>
                      ))}
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginTop: "1rem" }}
                        startIcon={<AddIcon />}
                        onClick={() =>
                          push({
                            feeType: "",
                            feeAmount: "",
                          })
                        }
                      >
                        Add Fee
                      </Button>
                    </>
                  )}
                </FieldArray>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  onClick={modalCloseUpdateAppointmentFee}
                  color="secondary"
                  startIcon={<CancelIcon />}
                  size="small"
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  autoFocus
                  endIcon={<CheckCircleIcon />}
                  size="small"
                  disabled={isLoading}
                >
                  CONFIRM{" "}
                  {isLoading && (
                    <CircularProgress size={20} style={{ marginLeft: "3px" }} />
                  )}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </ThemeProvider>
    </Dialog>
  );
};

export default AppointmentFee;
