import React, { useEffect, useState } from "react";
import axios from "axios";
import { withStyles } from "@material-ui/core/styles";
import { MenuItem, TextField } from "@material-ui/core";

// Api config
import config from "../../utils/config";

const MenuItemStyle = withStyles({
  root: {
    justifyContent: "center",
  },
})(MenuItem);

const SelectAppointmentFeeType = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const [feeTypes, setFeeTypes] = useState([]);

  const token = localStorage.getItem("token");
  useEffect(() => {
    async function fetchData() {
      const {
        data: { data: feeTypes },
      } = await axios.get(`${config.api}/appointment-fee-types`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      setFeeTypes(feeTypes);
    }
    fetchData();
  }, []);

  return (
    <TextField
      fullWidth
      select
      error={errors.feeType && touched.feeType}
      helperText={errors.feeType && touched.feeType ? errors.feeType : ""}
      {...field}
      {...props}
    >
      {feeTypes.map((option) => (
        <MenuItemStyle key={option.id} value={option.id}>
          {option.name}
        </MenuItemStyle>
      ))}
    </TextField>
  );
};
export default SelectAppointmentFeeType;
