import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import {
  IconButton,
  Typography,
  Slide,
  Toolbar,
  AppBar,
  Dialog,
  Button,
  Box,
  Step,
  Stepper,
  StepButton,
} from "@material-ui/core";
import MuiDialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import List from "./list";
import BodyMarkings from "./bodyMarkings";
import Others from "./Others";
import AssistanceInspection from "./assistanceInspection";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.grey[900],
  },
  appBar: {
    position: "relative",
    backgroundColor: "#FAE700",
    color: "#000",
  },
  gridCustom: {
    textAlign: "center !important",
  },
  stepper: {
    paddingLeft: "0",
    paddingRight: "0",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
      timeout={{ appear: 100, enter: 500, exit: 500 }}
    />
  );
});

const pms_steps = [
  "Body Markings",
  "PMS",
  "Lights",
  "Battery",
  "Aircondition",
  "Miscellaneous",
  "Fluids",
  "Belts",
  "Suspension",
  "Drive Train",
  "Tires",
  "Brakes",
  "Others",
];

const buying_steps = [
  "Mechanical",
  "Equipment & Functions",
  "Suspension & Underchassis",
  "Brakes & Tires",
  "Interior",
  "Exterior & Frame",
  "Documents",
  "Others",
  "Summary",
];

const InspectionDataDialog = (props) => {
  const {
    open,
    isLoading,
    data,
    handleInspectionClose,
    handleUpdateInspection,
  } = props;
  const classes = useStyles();

  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = useState([]);
  const [inspectionData, setInspectionData] = useState([]);

  useEffect(() => {
    if (props.data?.is_seller || props.data?.seller_name) {
      setSteps(buying_steps);
      setInspectionData(props.data?.buying_inspection);
      setInitialValues(props.data?.buying_inspection);
    } else {
      setSteps(pms_steps);
      setInspectionData(props.data?.inspection);
      setInitialValues(props.data?.inspection);
    }
  }, [props]);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const onChange = (id, field, value) => {
    const updatedData = inspectionData.map((item) => {
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });

    setInspectionData(updatedData);
  };

  const getChangedValues = () => {
    return inspectionData.filter((updatedItem) => {
      const initialItem = initialValues.find(
        (item) => item.id === updatedItem.id
      );
      for (let key in updatedItem) {
        if (updatedItem[key] !== initialItem[key]) {
          return true;
        }
      }
      return false;
    });
  };

  const handleUpdate = () => {
    const changedValues = getChangedValues();
    const type = props.data?.is_seller || props.data?.seller_name ? 'buyingInspection' : 'inspection'
    handleUpdateInspection(changedValues, type);
  };

  const getStepContent = (step) => {
    if (data && (data?.is_seller || data?.seller_name)) {
      switch (step) {
        case 0:
          return (
            <AssistanceInspection type="mechanical" data={inspectionData} />
          );
        case 1:
          return (
            <AssistanceInspection
              type="equipment_and_functions"
              data={inspectionData}
            />
          );
        case 2:
          return (
            <AssistanceInspection
              type="suspension_and_underchassis"
              data={inspectionData}
            />
          );
        case 3:
          return (
            <AssistanceInspection
              type="brakes_and_tires"
              data={inspectionData}
            />
          );
        case 4:
          return <AssistanceInspection type="interior" data={inspectionData} />;
        case 5:
          return <AssistanceInspection type="exterior" data={inspectionData} />;
        case 6:
          return (
            <AssistanceInspection type="documents" data={inspectionData} />
          );
        case 7:
          return <AssistanceInspection type="others" data={inspectionData} />;
        case 8:
          return (
            <AssistanceInspection
              type="others"
              data={inspectionData}
              summary={true}
              inspection_scores={
                props?.data?.extended_information?.buyinginspection_scores
              }
            />
          );
      }
    } else {
      switch (step) {
        case 0:
          return (
            <BodyMarkings
              title="Body Markings"
              type="bodymarkings"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 1:
          return (
            <List
              title="PMS"
              type="pms"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 2:
          return (
            <List
              title="Lights"
              type="lights"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 3:
          return (
            <List
              title="Battery"
              type="battery"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 4:
          return (
            <List
              title="Aircondition"
              type="aircondition"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 5:
          return (
            <List
              title="Miscellaneous"
              type="miscellaneous"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 6:
          return (
            <List
              title="Fluids"
              type="fluids"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 7:
          return (
            <List
              title="Belts"
              type="belts"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 8:
          return (
            <List
              title="Suspension"
              type="suspensions"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 9:
          return (
            <List
              title="Drive Train"
              type="drivetrains"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 10:
          return (
            <List
              title="Tires"
              type="tires"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 11:
          return (
            <List
              title="Brakes"
              type="brakes"
              data={inspectionData}
              onChange={onChange}
            />
          );
        case 12:
          return <Others data={props.data} />;
        default:
          return "default";
      }
    }
  };

  return (
    <>
      <div>
        <Dialog
          fullScreen
          open={open}
          onClose={handleInspectionClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleInspectionClose}
                aria-label="close"
              >
                <CloseIcon className={classes.button} />
              </IconButton>
              <Typography variant="h5" className={classes.title}>
                INSPECTION RESULT
              </Typography>
            </Toolbar>
          </AppBar>
          <MuiDialogContent dividers>
            <div>
              <Stepper
                nonLinear
                alternativeLabel
                activeStep={activeStep}
                className={classes.stepper}
              >
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <Box className={classes.paper}>
                <form autoComplete="off">
                  {getStepContent(activeStep)}
                  <Box style={{ display: "flex", flexDirection: "row" }} pt={2}>
                    <Box style={{ flex: "1 1 auto" }}>
                      {activeStep > 0 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            setActiveStep((prevStep) => prevStep - 1)
                          }
                        >
                          Back
                        </Button>
                      )}
                    </Box>
                    {activeStep < steps?.length - 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ChevronRightIcon />}
                        onClick={() =>
                          setActiveStep((prevStep) => prevStep + 1)
                        }
                      >
                        Next Page
                      </Button>
                    )}
                    {activeStep === steps?.length - 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        endIcon={<ChevronRightIcon />}
                        onClick={() => handleUpdate()}
                      >
                        Save Changes
                      </Button>
                    )}
                  </Box>
                </form>
              </Box>
            </div>
          </MuiDialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default InspectionDataDialog;
