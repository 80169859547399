import { Fragment } from "react";
import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Formik, Form, Field, FastField } from "formik";
import { object, string, number } from "yup";
import { TextField } from "formik-material-ui";
import CancelIcon from "@material-ui/icons/Cancel";
import CustomSelectTypeCancellation from "./customSelectTypeCancellation/CustomSelectTypeCancellation";
import CustomSelectReasonCancellation from "./customSelectReasonCancellation/CustomSelectReasonCancellation";

const useStyles = makeStyles((theme) => ({
  title: {
    backgroundColor: "#FAE700",
  },
  titleText: {
    color: "rgb(63,81,181) !important",
    fontWeight: "bold !important",
  },
  ul: {
    listStyleType: "none",
    padding: "0px",
    marginTop: "3px !important",
  },
  question: {
    marginBottom: "12px !important",
  },
  label: {
    marginRight: "5px",
    fontSize: "15px !important",
    color: "rgba(0, 0, 0, 0.54)",
    fontWeight: "600",
  },
  dataQuestion: {
    fontWeight: "600",
    fontSize: "15px !important",
  },
  data: {
    fontWeight: "600",
    textTransform: "capitalize",
    fontSize: "15px !important",
  },
}));

const CancelDialog = React.memo((props) => {
  const classes = useStyles();

  const { data, open, handleCancelClose, handleCancel } = props;

  const handleClose = () => {
    handleCancelClose();
  };

  const handleSubmit = (values) => {
    handleCancel(values);
  };

  const reservation_fee = data?.payment?.find(
    (payment) =>
      payment.payment_type === "reservation_fee" ||
      payment.payment_type === "downpayment_for_parts" ||
      payment.payment_type === "cancellation_fee"
  )?.amount_paid;

  const initialValues = {
    id: data.id,
    cancellation_type: "client_cancellation",
    reason_for_cancellation: "",
    other_inputs: "",
    status: 6,
    reservation_fee,
    cancellation_fee: reservation_fee,
  };

  const validationSchema = object().shape({
    cancellation_type: string().required(),
    reason_for_cancellation: string().required(),
    other_inputs: string().when("reason_for_cancellation", {
      is: "others",
      then: string().required("Other inputs is a required field"),
    }),
    cancellation_fee: number().when("reservation_fee", {
      is: (value) => value !== undefined && value !== null, // Check if cancellation_fee exists
      then: (schema) =>
        schema
          .typeError("Cancellation fee must be a number")
          .min(0, "Cancellation fee cannot be negative")
          .required("Cancellation fee is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

  if (Array.isArray(data) && data.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableBackdropClick
          fullWidth
          maxWidth="xs"
          classes={{ paper: classes.paper }}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting, setFieldValue }) => (
              <Form autoComplete="off">
                <DialogTitle
                  id="alert-dialog-title"
                  className={`${classes.title}`}
                >
                  <span className={classes.titleText}>REMARKS</span>
                </DialogTitle>
                <DialogContent dividers>
                  <ul className={classes.ul}>
                    <li className={classes.question}>
                      <div style={{ display: "flex" }}>
                        <div
                          className={classes.dataQuestion}
                        >{`Cancel this booking? This action is final and cannot be undone. Please check the details.`}</div>
                        <div className={classes.data}></div>
                      </div>
                    </li>
                    <li>
                      <div style={{ display: "flex" }}>
                        <div className={classes.label}>Customer Name : </div>
                        <div className={classes.data}>
                          {" "}
                          {data.name || ""} {data.lastname}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div style={{ display: "flex" }}>
                        <div className={classes.label}>Appointment Date : </div>
                        <div className={classes.data}>
                          {" "}
                          {moment(data.appointment_date).format("YYYY-MM-DD")}
                        </div>
                      </div>
                    </li>
                  </ul>
                  <Box mb={1}>
                    <FastField
                      name="cancellation_type"
                      label="Cancellation Type"
                      variant="outlined"
                      size="small"
                      component={CustomSelectTypeCancellation}
                      fullWidth
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("reason_for_cancellation", "");
                        setFieldValue("cancellation_type", value);
                      }}
                    />
                  </Box>
                  <Box mb={1}>
                    <Field
                      name="reason_for_cancellation"
                      label="Reason for cancellation"
                      variant="outlined"
                      size="small"
                      component={CustomSelectReasonCancellation}
                      fullWidth
                      onChange={(e) => {
                        const { value } = e.target;
                        setFieldValue("reason_for_cancellation", value);
                      }}
                    />
                  </Box>
                  {values.reason_for_cancellation === "others" && (
                    <Box mb={1}>
                      <Field
                        name="other_inputs"
                        label="Other inputs"
                        variant="outlined"
                        size="small"
                        component={TextField}
                        fullWidth
                      />
                    </Box>
                  )}
                  {reservation_fee && (
                    <Box mb={1}>
                      <Field
                        fullWidth
                        name="cancellation_fee"
                        component={TextField}
                        label="Cancellation Fee"
                        size="small"
                        variant="outlined"
                      />
                    </Box>
                  )}
                  {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleClose}
                    color="secondary"
                    startIcon={<CancelIcon />}
                    size="small"
                    variant="contained"
                    disabled={isSubmitting}
                  >
                    Close
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    color="primary"
                    variant="contained"
                    autoFocus
                    size="small"
                    endIcon={
                      isSubmitting ? (
                        <CheckCircleIcon size="0.9rem" />
                      ) : undefined
                    }
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      </div>
    </Fragment>
  );
});
export default CancelDialog;
